import classNames from "classnames";
import "./header-form.scss";
import InlineText from "../inline-text/inline-text";

export interface HeaderFormProps {
  label: string;
  value?: string;
}

export function HeaderForm({ label, value }: HeaderFormProps) {
  return (
    <div className="header-form">
      <span className="flex items-center gap-2">
        <label className="print-normal whitespace-nowrap font-bold">
          {label}
        </label>
        <h4
          className={classNames(
            "print-normal block relative font-bold border-b border-black w-full",
            value ? "top-[1px]" : ""
          )}
        >
          {value ? (
            <span className="min-h-[2rem] flex items-center">{value}</span>
          ) : (
            <InlineText />
          )}
        </h4>
      </span>
    </div>
  );
}

export default HeaderForm;
