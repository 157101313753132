import {
  useGetRequestByIdQy,
  useProcessReceivedRequestQy,
} from "@core/query/request.query";
import "./received-info.scss";
import { useNavigate, useParams } from "react-router";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import {
  PurchaseRequestControllerGetDataAsList200Response,
  ReceivePurchaseRequestDto,
} from "@api/api";

export function ReceivedInfo() {
  const { showDialog, showWarning } = useNotificationContext();
  const navigate = useNavigate();
  const { requestId } = useParams();
  const receivedIcon = "/images/received.jpg";

  const handleReceiveSuccess = () => {
    showDialog(
      ``,
      `The Purchase Request is successfully received`,
      receivedIcon
    );
    navigate(`../${requestId}`);
  };
  const handleReceiveError = () => {
    navigate(`../${requestId}`);
  };
  const { mutate: recieveRequest } = useProcessReceivedRequestQy(
    handleReceiveSuccess,
    handleReceiveError
  );

  const handleGetApiSuccess = (
    data: PurchaseRequestControllerGetDataAsList200Response
  ) => {
    if (data && data.count && data.count > 0) {
      const requestData = data.data?.[0];
      let receiver = null;

      switch (requestData?.reviewer) {
        case "CGSO":
          receiver = { is_gso_received: true };
          break;
        case "CTO":
          receiver = { is_treasurer_received: true };
          break;
        case "CVMO":
        case "CMO":
          receiver = { is_mayor_received: true };
          break;
        case "CGSO_FF":
          receiver = { is_gso_ff_received: true };
          break;
        case "CBO":
          receiver = { is_budget_received: true };
          break;
      }

      if (!receiver) {
        const allReceived =
          requestData?.is_gso &&
          requestData?.is_treasurer &&
          requestData?.is_mayor &&
          requestData?.is_gso_ff &&
          requestData?.is_budget;

        receiver = allReceived ? { is_bac_received: true } : null;
      }

      if (!receiver) {
        showWarning("Receiver not found");
        navigate(`../${requestId}`);
        return;
      }

      const received = {
        code: requestData?.code || "",
        ...receiver,
      } as ReceivePurchaseRequestDto;

      recieveRequest(received);
    }
  };

  useGetRequestByIdQy(requestId || "", !!requestId, handleGetApiSuccess);

  return (
    <div className="received-info">
      <div className="p-7 flex justify-center items-center flex-col h-screen">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        <h1>Receiving Purchase Request</h1>
        <small>Please wait while we are processing the request</small>
      </div>
    </div>
  );
}

export default ReceivedInfo;
