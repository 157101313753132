import classNames from "classnames";
import InlineText from "../inline-text/inline-text";
import "./signature-form.scss";

export interface SignatureFormProps {
  label: string;
  inlineClass?: string;
  textStart?: "start" | "center";
}

export function SignatureForm({
  label,
  inlineClass = "border-b border-b-gray-500 w-80",
  textStart = "start",
}: SignatureFormProps) {
  return (
    <div className="signature-form">
      <div className="print-normal font-bold text-sm">
        <InlineText className={classNames(inlineClass)} textStart={textStart} />
      </div>
      <small
        className={classNames(
          "print-normal block relative top-[1px] text-center"
        )}
      >
        {label}
      </small>
    </div>
  );
}

export default SignatureForm;
