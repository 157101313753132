import HeaderContent from "@shared/ui/header-content/header-content";
import { useEditMonitor } from "./edit-monitor.hook";
import "./edit-monitor.scss";
import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import FormMonitor from "../form-monitor/form-monitor";
import TextBlock from "@shared/ui/text-block/text-block";
import { Accordion, AccordionTab } from "primereact/accordion";
import { tagTemplate } from "@core/utility/data-table-template";
import { currencyFormat } from "@shared/formats/currency-format";
import { format } from "date-fns";
import { SETTINGS } from "@core/utility/settings";
import { SplitButton } from "primereact/splitbutton";
import { Button } from "primereact/button";
import { dateFormat } from "@shared/formats/date-time-format";
import { FormCategoryItemProvider } from "@domain/item/new-item/form-category-item/form-category-item.context";
import { FormBrandItemProvider } from "@domain/item/new-item/form-brand-item/brand.context";
import { FormUnitItemProvider } from "@domain/item/new-item/form-unit-item/form-unit-item.context";
import FormManualMonitor from "../form-manual-monitor/form-manual-monitor";
import PrintInventoryQr from "../print-inventory-qr/print-inventory-qr";
import PrintIcsForm from "../print-ics-form/print-ics-form";
import PrintParForm from "../print-par-form/print-par-form";
import FormMonitorPrintFields from "../form-monitor-print-fields/form-monitor-print-fields";
import PrintPhysicalCount from "../print-physical-count/print-physical-count";
import PrintInventoryEquipment from "../print-inventory-equipment/print-inventory-equipment";
import PrintPropertyIssue from "../print-property-issue/print-property-issue";
import PrintPhysicalCountSemiExpendable from "../print-physical-count-semi-expendable/print-physical-count-semi-expendable";
import { TabPanel, TabView } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";

export function EditMonitor() {
  const navigate = useNavigate();
  const {
    inventoryData,
    isLoading,
    inventoryError,
    formMethod,
    actionItems,
    isEditLoading,
    componentRef,
    icsPrintRef,
    parPrintRef,
    airNo,
    entityName,
    receivedFrom,
    receivedFromPosition,
    receivedFromDate,
    receivedBy,
    receivedByPosition,
    expenseCode,
    serialNo,
    physicalPrintRef,
    inventoryEquipmentRef,
    prItems,
    propertyIssueRef,
    physicalSemiExpendableRef,
    printableItems,
    selectedPrint,
    setSelectedPrint,
    updateAction,
    assignAction,
    handlePrintIcs,
    handlePrintPar,
    handlePrintPhysicalCount,
    handlePrintInventoryEquipment,
    handlePrintPropertyIssue,
    handlePrintSemiExpendable,
  } = useEditMonitor();

  const handleBack = () => {
    navigate("../");
  };

  const printSection = (
    <div style={{ display: "none" }}>
      <div ref={componentRef}>
        <PrintInventoryQr
          code={inventoryData?.code || ""}
          propertyNo={inventoryData?.inventory_no || ""}
          expenseCode={expenseCode || ""}
          serialNo={serialNo || ""}
        />
      </div>
    </div>
  );
  const printIcsForm = (
    <div style={{ display: selectedPrint === "ICS" ? "inline" : "none" }}>
      <section className="w-full flex justify-end mt-4">
        <Button
          label="Print"
          outlined
          severity="secondary"
          onClick={() => handlePrintIcs()}
        />
      </section>

      <div ref={icsPrintRef}>
        {inventoryData && <PrintIcsForm items={prItems} data={inventoryData} />}
      </div>
    </div>
  );
  const printParForm = (
    <div style={{ display: selectedPrint === "PAR" ? "inline" : "none" }}>
      <section className="w-full flex justify-end mt-4">
        <Button
          label="Print"
          outlined
          severity="secondary"
          onClick={() => handlePrintPar()}
        />
      </section>

      <div ref={parPrintRef}>
        {inventoryData && (
          <PrintParForm
            items={prItems}
            data={inventoryData}
            airNo={airNo}
            entityName={entityName}
            receivedFrom={receivedFrom}
            receivedFromPosition={receivedFromPosition}
            receivedFromDate={receivedFromDate}
            receivedBy={receivedBy}
            receivedByPosition={receivedByPosition}
          />
        )}
      </div>
    </div>
  );
  const printPhysicalCount = (
    <div
      style={{
        display: selectedPrint === "Physical Count" ? "inline" : "none",
      }}
    >
      <section className="w-full flex justify-end mt-4">
        <Button
          label="Print"
          outlined
          severity="secondary"
          onClick={() => handlePrintPhysicalCount()}
        />
      </section>
      <div ref={physicalPrintRef}>
        {inventoryData && (
          <PrintPhysicalCount data={inventoryData} items={prItems} />
        )}
      </div>
    </div>
  );
  const printInventoryEquipment = (
    <div
      style={{
        display: selectedPrint === "Inventory Equipment" ? "inline" : "none",
      }}
    >
      <section className="w-full flex justify-end mt-4">
        <Button
          label="Print"
          outlined
          severity="secondary"
          onClick={() => handlePrintInventoryEquipment()}
        />
      </section>
      <div ref={inventoryEquipmentRef}>
        {inventoryData && (
          <PrintInventoryEquipment data={inventoryData} items={prItems} />
        )}
      </div>
    </div>
  );
  const printPropertyIssue = (
    <div
      style={{
        display: selectedPrint === "Property Issue" ? "inline" : "none",
      }}
    >
      <section className="w-full flex justify-end mt-4">
        <Button
          label="Print"
          outlined
          severity="secondary"
          onClick={() => handlePrintPropertyIssue()}
        />
      </section>
      <div ref={propertyIssueRef}>
        <PrintPropertyIssue items={prItems} />
      </div>
    </div>
  );
  const printPhysicalCountSemiExpendable = (
    <div
      style={{
        display: selectedPrint === "Semi Expendable" ? "inline" : "none",
      }}
    >
      <section className="w-full flex justify-end mt-4">
        <Button
          label="Print"
          outlined
          severity="secondary"
          onClick={() => handlePrintSemiExpendable()}
        />
      </section>
      <div ref={physicalSemiExpendableRef}>
        {inventoryData && (
          <PrintPhysicalCountSemiExpendable
            data={inventoryData}
            items={prItems}
          />
        )}
      </div>
    </div>
  );
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const subHeader = () => {
    const tag = tagTemplate(inventoryData?.status_name || "PRE-REG");
    const dateUpdated = inventoryData?.updated_at;

    return (
      <section className="flex flex-col md:flex-row md:justify-between">
        <section className="mb-5 flex justify-between flex-col">
          <div>{tag}</div>
          <span className="flex gap-1 mt-1">
            <label className="hint">Date Updated:</label>
            <p className="hint">
              {dateUpdated
                ? format(new Date(dateUpdated), SETTINGS.dateFormat)
                : ""}
            </p>
          </span>
        </section>
      </section>
    );
  };
  const inventoryAccordion = (
    <Accordion activeIndex={[0]} multiple>
      <AccordionTab header="Purchase Order Information">
        <TextBlock
          label="PO Number"
          value={inventoryData?.po_no || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="PO Date"
          value={dateFormat(inventoryData?.po_date) || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Procurement Mode"
          value={inventoryData?.mode_of_procurement || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Resolution No."
          value={inventoryData?.resolution_no || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="IAR No."
          value={inventoryData?.iar_no || "-"}
          mode="vertical"
          className="mb-4"
        />
      </AccordionTab>
      <AccordionTab header="Purchase Request Information">
        <TextBlock
          label="PR Number"
          value={inventoryData?.pr_no || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="PR Date"
          value={dateFormat(inventoryData?.pr_date) || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="PR Category"
          value={inventoryData?.pr_category_name || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="PR Department"
          value={inventoryData?.pr_department_name || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="PR Section"
          value={(inventoryData as any)?.pr_section || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="PR Purpose"
          value={(inventoryData as any)?.pr_purpose || "-"}
          mode="vertical"
          className="mb-4"
        />
      </AccordionTab>
      <AccordionTab header="Item Information">
        <TextBlock
          label="Name"
          value={inventoryData?.item_name || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Delivery Brand"
          value={inventoryData?.brand_name || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Quantity"
          value={inventoryData?.quantity?.toString() || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Unit"
          value={inventoryData?.unit_name || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Price"
          value={currencyFormat(inventoryData?.item_price || 0, "PHP")}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Category"
          value={inventoryData?.po_category_name || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Item Description"
          value={inventoryData?.item_description || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Delivery Description"
          value={inventoryData?.delivery_description || "-"}
          mode="vertical"
          className="mb-4"
        />
      </AccordionTab>
      <AccordionTab header="Supplier">
        <TextBlock
          label="Name"
          value={inventoryData?.supplier || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Contact"
          value={inventoryData?.supplier_contact || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Address"
          value={inventoryData?.supplier_address || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="Email"
          value={inventoryData?.supplier_email || "-"}
          mode="vertical"
          className="mb-4"
        />
        <TextBlock
          label="TIN"
          value={inventoryData?.supplier_tin || "-"}
          mode="vertical"
          className="mb-4"
        />
      </AccordionTab>
      <AccordionTab header="Print Fields">
        <FormMonitorPrintFields />
      </AccordionTab>
    </Accordion>
  );
  const mainContent = (
    <div>
      {subHeader()}

      <section className="grid grid-cols-1 bg-white">
        <TabView>
          <TabPanel header="Information">
            <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
              <FormMonitor />
              <section className="p-6">
                {!inventoryData?.purchase_request ? (
                  <FormCategoryItemProvider>
                    <FormBrandItemProvider>
                      <FormUnitItemProvider>
                        <FormManualMonitor />
                      </FormUnitItemProvider>
                    </FormBrandItemProvider>
                  </FormCategoryItemProvider>
                ) : (
                  inventoryAccordion
                )}
              </section>
            </section>
          </TabPanel>
          <TabPanel header="Forms">
            <Dropdown
              value={selectedPrint}
              onChange={(e) => setSelectedPrint(e.value)}
              options={printableItems}
              placeholder="Select a form to print"
              className="w-full md:w-14rem"
            />

            {printPhysicalCount}
            {printInventoryEquipment}
            {printIcsForm}
            {printParForm}
            {printPropertyIssue}
            {printPhysicalCountSemiExpendable}
          </TabPanel>
        </TabView>
      </section>
    </div>
  );

  return (
    <div className="edit-monitor">
      <HeaderContent title="Edit Monitor" onBack={handleBack}>
        <section className="flex gap-2">
          <Button
            label="Update"
            onClick={updateAction}
            disabled={isEditLoading}
          ></Button>
          <SplitButton
            label="Assign"
            onClick={assignAction}
            model={actionItems}
            disabled={isEditLoading}
            outlined
          />
        </section>
      </HeaderContent>
      {printSection}

      <div className="p-7">
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {inventoryError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </div>
    </div>
  );
}

export default EditMonitor;
