import { GetInventoryDto, GetPrItemDto } from "@api/api";
import "./print-ics-form.scss";
import QRCode from "react-qr-code";
import { dateFormat } from "@shared/formats/date-time-format";
import { currencyFormat } from "@shared/formats/currency-format";
import SignatureForm from "@core/ui/signature-form/signature-form";
import HeaderForm from "@core/ui/header-form/header-form";

export interface PrintIcsFormProps {
  data: GetInventoryDto;
  items: GetPrItemDto[];
}

export function PrintIcsForm({ data, items }: PrintIcsFormProps) {
  const qrCode = `MONITORCODE-${data?.code}`;
  return (
    <div className="print-ics-form m-8 border-solid border-2 border-gray-500 p-8">
      <style type="text/css" media="print">
        {
          "\
  @page { size: A4; }\
"
        }
      </style>
      <header className="flex">
        <div className="relative top-0 mx-4 w-full text-center">
          <h2>Inventory Custodian Slip</h2>
        </div>
        <div className="mx-4">
          <QRCode value={qrCode} size={120} />
        </div>
      </header>

      <section className="grid grid-cols-2 grid-rows-1 gap-4 mb-4 mt-24">
        <div>
          <HeaderForm label="IAR No:" />
          <HeaderForm label="IB PO No:" value={data?.po_no} />
          <HeaderForm
            label="City Health Office Supplier:"
            value={data?.supplier}
          />
        </div>
        <div className="ml-16">
          <HeaderForm label="ICS No:" value={data?.ics_no} />
          <HeaderForm label="Date:" value={dateFormat(new Date().toString())} />
        </div>
      </section>

      <section className="mt-10">
        <table className="print-table w-full text-sm text-left rtl:text-right text-gray-500 border-t-2 border-b-2 border-l-2 border-r-2">
          <tbody className="border-t border-b border-l-0 border-r-0">
            <tr>
              <th className="print-normal px-2 py-1 border  w-20">Quantity</th>
              <th className="print-normal px-2 py-1 border ">Unit</th>
              <th className="print-normal px-2 py-1 border ">Unit Cost</th>
              <th className="print-normal px-2 py-1 border ">Total Cost</th>
              <th className="print-normal px-2 py-1 border ">Description</th>
              <th className="print-normal px-2 py-1 border ">
                Inventory Item No.
              </th>
              <th className="print-normal px-2 py-1 border ">
                Estimated Useful Life
              </th>
            </tr>

            {items.map((item) => (
              <tr
                key={item.code}
                className="border-t border-b border-l-0 border-r-0"
              >
                <td className="print-normal px-2 py-1 border ">
                  {item.quantity}
                </td>
                <td className="print-normal px-2 py-1 border ">
                  {item.unit_name}
                </td>
                <td className="print-normal px-2 py-1 border ">
                  {currencyFormat(item.price || 0, "PHP")}
                </td>
                <td className="print-normal px-2 py-1 border ">
                  {currencyFormat(item.price * item.quantity, "PHP")}
                </td>
                <td className="print-normal px-2 py-1 border ">
                  <pre>{item.item_name}</pre>
                  <pre>{item.description}</pre>
                </td>
                <td className="print-normal px-2 py-1 border"></td>
                <td className="print-normal px-2 py-1 border"></td>
              </tr>
            ))}

            <tr>
              <td colSpan={7}></td>
            </tr>
            <tr>
              <td className="border px-2 pt-4 pb-1" colSpan={7}>
                <section className="grid grid-cols-2 grid-rows-1 gap-4 py-1">
                  <div>
                    <h5 className="print-normal block mb-5">Received From:</h5>
                    <div className="flex flex-col items-center justify-center gap-2">
                      <SignatureForm label="Name" textStart="center" />
                      <SignatureForm
                        label="Position"
                        inlineClass="border-b border-b-gray-500 w-60"
                        textStart="center"
                      />
                      <SignatureForm
                        label="Date"
                        inlineClass="border-b border-b-gray-500 w-60"
                        textStart="center"
                      />
                    </div>
                  </div>
                  <div>
                    <h5 className="print-normal block mb-5">Received By:</h5>
                    <div className="flex flex-col items-center justify-center">
                      <SignatureForm label="Name" textStart="center" />
                      <SignatureForm
                        label="Position"
                        inlineClass="border-b border-b-gray-500 w-60"
                        textStart="center"
                      />
                    </div>
                  </div>
                </section>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default PrintIcsForm;
