import { Outlet } from "react-router-dom";
import "./main-shell";
import { Toast } from "primereact/toast";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export function MainShell() {
  const { progress, toastRef, dialog, hideDialog } = useNotificationContext();
  
  const footerContent = (
    <div className="flex justify-center">
      <Button label="Continue" onClick={() => hideDialog()} />
    </div>
  );

  return (
    <div className="main-shell">
      <Toast ref={toastRef} position="bottom-left" />
      {progress?.show && (
        <ProgressBar
          mode="indeterminate"
          style={{
            height: "6px",
            zIndex: 999999,
            position: "fixed",
            width: "100%",
          }}
        ></ProgressBar>
      )}
      <Dialog
        header={dialog?.header ?? ""}
        visible={Boolean(dialog)}
        style={{ width: "50vw" }}
        onHide={() => {
          hideDialog();
        }}
        footer={footerContent}
      >
        <div className="m-24">
          {dialog?.icon ? (
            <div className="text-center flex justify-center">
              <img className="w-80" src={dialog.icon} />
            </div>
          ) : null}
          {dialog?.message ? (
            <p className="text-center mt-14 font-bold">{dialog.message}</p>
          ) : null}
        </div>
      </Dialog>

      <Outlet />
    </div>
  );
}

export default MainShell;
