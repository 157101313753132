import { GetInventoryDto, GetPrItemDto } from "@api/api";
import "./print-inventory-equipment.scss";
import QRCode from "react-qr-code";
import { currencyFormat } from "@shared/formats/currency-format";
import { dateFormat } from "@shared/formats/date-time-format";
import InlineText from "@core/ui/inline-text/inline-text";

export interface PrintInventoryEquipmentProps {
  data: GetInventoryDto;
  items: GetPrItemDto[];
}

export function PrintInventoryEquipment({
  data,
  items,
}: PrintInventoryEquipmentProps) {
  const qrCode = `MONITORCODE-${data?.code}`;
  return (
    <section>
      <style type="text/css" media="print">
        {
          "\
  @page { size: legal landscape; }\
"
        }
      </style>
      <div className="print-inventory-equipment mx-4 mt-5">
        <header className="flex">
          <div className="relative top-0 mx-4 w-full text-center">
            <h2>INVENTORY OF EQUIPMENT</h2>
            <h3>(Supplies of Equipment but not both)</h3>
            <h4>As of {dateFormat(data.date_assigned)}</h4>
          </div>
          <div className="mx-4">
            <QRCode value={qrCode} size={120} />
          </div>
        </header>

        <section className="mb-4 mt-24 text-center">
          <table className="ml-auto mr-auto">
            <tbody>
              <tr>
                <td>
                  <div className="flex items-end">
                    <InlineText
                      className="border-b border-b-gray-500 w-40"
                      inputClass="w-28"
                    />
                  </div>
                </td>
                <td>
                  <div className="flex items-end">
                    <InlineText
                      className="border-b border-b-gray-500 w-40"
                      inputClass="w-28"
                    />
                    <span className="relative top-2">,</span>
                  </div>
                </td>
                <td>
                  <div className="flex items-end">
                    <InlineText
                      className="border-b border-b-gray-500 w-40"
                      inputClass="w-28"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <small>(Agency)</small>
                </td>
                <td>
                  <small>(Name of Accountable Officer)</small>
                </td>
                <td>
                  <small>(Designation)</small>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="mt-10">
          <table className="print-table w-full text-sm text-left rtl:text-right text-gray-500 border-t-2 border-b-2 border-l-2 border-r-2">
            <thead className="border-t border-b border-l-0 border-r-0">
              <tr className="thead head-1">
                <th colSpan={2} rowSpan={3}>
                  Article / Description
                </th>
                <th>New Property</th>
                <th className="border-b text-center">Unit</th>
                <th>Unit</th>
                <th colSpan={2} className="border-b text-center">
                  Balance Per Stock Card
                </th>
                <th colSpan={2} className="border-b text-center">
                  On Hand Per Count
                </th>
                <th>Date</th>
                <th>Remarks</th>
              </tr>
              <tr className="thead head-2">
                <th>Number</th>
                <th></th>
                <th className="border-b text-center">Value</th>
                <th className="border-b text-center">Qty</th>
                <th className="border-b text-center">Value</th>
                <th className="border-b text-center">Qty</th>
                <th className="border-b text-center">Value</th>
                <th className="border-b text-center">Acquired</th>
                <th className="border-b text-center">State Whereabouts</th>
              </tr>
              <tr className="thead head-3">
                <td></td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr
                  key={item.code}
                  className="border-t border-b border-l-0 border-r-0"
                >
                  <td className="tdata">
                    <pre>{item.item_name}</pre>
                    <pre>{item.description}</pre>
                  </td>
                  <td className="tdata"></td>
                  <td className="tdata">{item.unit_name}</td>
                  <td className="tdata">{currencyFormat(item.price, "PHP")}</td>
                  <td>{item.quantity}</td>
                  <td className="tdata"></td>
                  <td></td>
                  <td className="tdata"></td>
                  <td className="tdata">{dateFormat(data?.date_assigned)}</td>
                  <td className="tdata">{data.remarks}</td>
                </tr>
              ))}
              <tr>
                <td className="tdata"></td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </section>
  );
}

export default PrintInventoryEquipment;
