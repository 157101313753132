import { dateFormat } from "@shared/formats/date-time-format";
import "./print-physical-count-semi-expendable.scss";
import { GetInventoryDto, GetPrItemDto } from "@api/api";
import InlineText from "@core/ui/inline-text/inline-text";
import { currencyFormat } from "@shared/formats/currency-format";
import classNames from "classnames";
import SignatureForm from "@core/ui/signature-form/signature-form";

export interface PrintPhysicalCountSemiExpendableProps {
  data: GetInventoryDto;
  items: GetPrItemDto[];
}

export function PrintPhysicalCountSemiExpendable({
  data,
  items,
}: PrintPhysicalCountSemiExpendableProps) {
  return (
    <div className="print-physical-count-semi-expendable">
      <header className="flex">
        <div className="relative top-0 mx-4 w-full text-center">
          <h2>REPORT ON THE PHYSICAL COUNT OF SEMI - EXPENDABLE PROPERTY</h2>
          <h2>
            __________OFFICE, FURNITURE AND FIXTURE, IT AND SOFTWARE, BOOKS,
            SPORTS AND OTHER, PLANT EQUIPMENT_________
          </h2>
          <h3>(Type of Semi - Expendable Property)</h3>
          <h4>As of {dateFormat(data.date_assigned)}</h4>
        </div>
      </header>

      <section className="mb-4 mt-24 text-center">
        <div className="flex items-center gap-2">
          <span>Fund Cluster:</span>{" "}
          <div className="flex items-end">
            <InlineText
              className="border-b border-b-gray-500 w-40"
              inputClass="w-28"
            />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <span>For which</span>{" "}
          <div className="flex items-end">
            <InlineText
              className="border-b border-b-gray-500 w-40"
              inputClass="w-28"
            />
            <span className="relative top-2">,</span>
          </div>
          <div className="flex items-end">
            <InlineText
              className="border-b border-b-gray-500 w-40"
              inputClass="w-28"
            />
            <span className="relative top-2">,</span>
          </div>
          <div className="flex items-end">
            <InlineText
              className="border-b border-b-gray-500 w-40"
              inputClass="w-28"
            />
            <span className="relative top-2">,</span>
          </div>
          <span>is accountable, having assumed such accountability, on</span>
          <div className="flex items-end">
            <InlineText
              className="border-b border-b-gray-500 w-40"
              inputClass="w-28"
            />
          </div>
        </div>
      </section>

      <section className="mt-10">
        <table className="print-table w-full text-sm text-left rtl:text-right text-gray-500 border-t-2 border-b-2 border-l-2 border-r-2">
          <tr>
            <th rowSpan={2}>Article/Date Acquired</th>
            <th rowSpan={2}>Description</th>
            <th rowSpan={2}>Semi-Expendable Property No.</th>
            <th rowSpan={2}>Unit of Measure</th>
            <th rowSpan={2}>Unit Value</th>
            <th>Balance Per Card</th>
            <th>On Hand Per Count</th>
            <th colSpan={2}>Shortage/Overage</th>
            <th rowSpan={2}>Remarks</th>
          </tr>
          <tr>
            <td>(Quantity)</td>
            <td>(Quantity)</td>
            <td>Quantity</td>
            <td>Value</td>
          </tr>
          <tbody>
            {items.map((item) => (
              <tr
                key={item.code}
                className="border-t border-b border-l-0 border-r-0"
              >
                <td className="tdata"></td>
                <td>
                  <pre>{item.item_name}</pre>
                  <pre>{item.description}</pre>
                </td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata">{item.unit_name} </td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td className="border px-2 pt-4 pb-1" colSpan={10}>
              <section className="grid grid-cols-3 grid-rows-1 gap-4 py-1 mt-16">
                <div>
                  <h5 className="print-normal block mb-5">Prepared:</h5>
                  <div className="flex flex-col items-center justify-center mb-5">
                    <SignatureForm
                      label="Asset Managemetn Division Head"
                      textStart="center"
                    />
                  </div>
                </div>

                <div>
                  <h5 className="print-normal block mb-5">Noted:</h5>
                  <div className="flex flex-col items-center justify-center mb-5">
                    <SignatureForm label="CGSO-Head" textStart="center" />
                  </div>
                </div>

                <div>
                  <div className="flex flex-col items-center justify-center mb-5 mt-5">
                    <SignatureForm label="Supply Officer" textStart="center" />
                  </div>
                </div>
              </section>
            </td>
          </tr>
        </table>
      </section>
    </div>
  );
}

export default PrintPhysicalCountSemiExpendable;
