import classNames from "classnames";
import "./print-property-issue.scss";
import { GetPrItemDto } from "@api/api";
import InlineText from "@core/ui/inline-text/inline-text";

/* eslint-disable-next-line */
export interface PrintPropertyIssueProps {
  items: GetPrItemDto[];
}

export function PrintPropertyIssue({ items }: PrintPropertyIssueProps) {
  return (
    <div className="print-property-issue m-6">
      <style type="text/css" media="print">
        {
          "\
@page { size: legal landscape; }\
"
        }
      </style>

      <header className="flex">
        <div className="relative top-0 mx-4 w-full text-center">
          <h2>REGISTRY OF SEMI-EXPENDABLE PROPERTY ISSUED</h2>
        </div>
      </header>

      <section className="grid grid-cols-2 grid-rows-1 gap-4 mb-4 mt-24">
        <div>
          <span className="flex items-center gap-2">
            <label className="print-normal whitespace-nowrap font-bold">
              ENTITY NAME:
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full"
              )}
            >
              <InlineText />
            </h4>
          </span>
          <span className="flex items-center gap-2 mt-2">
            <label className="print-normal whitespace-nowrap font-bold">
              SEMI-EXPANDABLE PROPERTY:
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full"
              )}
            >
              <InlineText />
            </h4>
          </span>
        </div>
        <div className="ml-16">
          <span className="flex items-center gap-2">
            <label className="whitespace-nowrap print-normal font-bold">
              FUND CLUSTER:
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full"
              )}
            >
              <InlineText />
            </h4>
          </span>
          <span className="flex items-center gap-2 mt-2">
            <label className="whitespace-nowrap print-normal font-bold">
              SHEET NO:
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full"
              )}
            >
              <InlineText />
            </h4>
          </span>
        </div>
      </section>

      <section className="mt-10">
        <table className="print-table w-full text-sm text-left rtl:text-right text-gray-500 border-t-2 border-b-2 border-l-2 border-r-2">
          <tr className="thead head-1">
            <td rowSpan={2}>DATE</td>
            <td colSpan={2} className="border-b">
              REFERENCE
            </td>
            <td rowSpan={2}>ITEM DESCRIPTION</td>
            <td rowSpan={2}>ESTIMATED USEFUL LIFE</td>
            <td colSpan={2} className="border-b">
              ISSUED
            </td>
            <td colSpan={2} className="border-b">
              RETURNED
            </td>
            <td colSpan={2} className="border-b">
              RE-ISSUED
            </td>
            <td className="border-b">DISPOSED</td>
            <td className="border-b">BALANCE</td>
            <td rowSpan={2}>AMOUNT</td>
            <td rowSpan={2}>REMARKS</td>
          </tr>
          <tr className="thead head-2">
            <td>ICS/RRSP NO.</td>
            <td>SEMI-EXPENDABLE PROPERTY NO.</td>
            <td>QTY</td>
            <td>OFFICE/OFFICER</td>
            <td>QTY</td>
            <td>OFFICE/OFFICER</td>
            <td>QTY</td>
            <td>OFFICE/OFFICER</td>
            <td>QTY</td>
            <td>QTY</td>
          </tr>
          <tbody>
            {items.map((item) => (
              <tr
                key={item.code}
                className="border-t border-b border-l-0 border-r-0"
              >
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <pre>{item.item_name}</pre>
                  <pre>{item.description}</pre>
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
                <td className="tdata">
                  <InlineText />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default PrintPropertyIssue;
