/* tslint:disable */
/* eslint-disable */
/**
 * CGSO-Tagbilaran
 * CGSO-Tagbilaran API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivatePersonDto
 */
export interface ActivatePersonDto {
    /**
     * 
     * @type {string}
     * @memberof ActivatePersonDto
     */
    'person_code': string;
    /**
     * 
     * @type {string}
     * @memberof ActivatePersonDto
     */
    'activation_code': string;
    /**
     * 
     * @type {string}
     * @memberof ActivatePersonDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AddPersonDto
 */
export interface AddPersonDto {
    /**
     * 
     * @type {CreatePersonDto}
     * @memberof AddPersonDto
     */
    'person': CreatePersonDto;
    /**
     * 
     * @type {any}
     * @memberof AddPersonDto
     */
    'file'?: any;
}
/**
 * 
 * @export
 * @interface AdminChangePasswordDto
 */
export interface AdminChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof AdminChangePasswordDto
     */
    'person_code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminChangePasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface BidderControllerGetDataAsList200Response
 */
export interface BidderControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof BidderControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetBidderDto>}
     * @memberof BidderControllerGetDataAsList200Response
     */
    'data'?: Array<GetBidderDto>;
}
/**
 * 
 * @export
 * @interface BidderControllerGetDataAsList200ResponseAllOf
 */
export interface BidderControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetBidderDto>}
     * @memberof BidderControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetBidderDto>;
}
/**
 * 
 * @export
 * @interface BulkItemsDto
 */
export interface BulkItemsDto {
    /**
     * 
     * @type {Array<CreateItemDto>}
     * @memberof BulkItemsDto
     */
    'items': Array<CreateItemDto>;
}
/**
 * 
 * @export
 * @interface ChangePasswordDto
 */
export interface ChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'person_code': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'activation_code': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CoreApiPaginatedResponse
 */
export interface CoreApiPaginatedResponse {
    /**
     * 
     * @type {number}
     * @memberof CoreApiPaginatedResponse
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface CreateBidderDto
 */
export interface CreateBidderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'tin'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBidderDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateBidderDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateBidderDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBidderDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateInventoryDto
 */
export interface CreateInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'batch'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'inventory_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'pr_item_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'property_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'iar_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'date_assigned'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'office'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'building'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateInventoryDto
     */
    'end_of_life'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInventoryDto
     */
    'is_active': boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'par_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryDto
     */
    'ics_no'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateInventoryDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface CreateInventoryStatusDto
 */
export interface CreateInventoryStatusDto {
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryStatusDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryStatusDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInventoryStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateInventoryStatusDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryStatusDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateInventoryStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInventoryStatusDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateItemDto
 */
export interface CreateItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateItemDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemDto
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemDto
     */
    'brand'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemDto
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateItemDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateItemDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateManualInventoryDto
 */
export interface CreateManualInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'inventory_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'po_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'resolution_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'po_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'po_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'mode_of_procurement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'supplier'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'supplier_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'supplier_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'supplier_contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'supplier_tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'pr_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'pr_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'pr_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'pr_department'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'pr_section'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'pr_purpose'?: string;
    /**
     * `code` from `item` table.
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'pr_item_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'delivery_brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'delivery_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'property_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'iar_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'date_assigned'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'office'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'building'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateManualInventoryDto
     */
    'end_of_life'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateManualInventoryDto
     */
    'is_active': boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'par_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManualInventoryDto
     */
    'ics_no'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateManualInventoryDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface CreatePIDDto
 */
export interface CreatePIDDto {
    /**
     * 
     * @type {Array<CreatePrItemDeliveryDto>}
     * @memberof CreatePIDDto
     */
    'delivery'?: Array<CreatePrItemDeliveryDto>;
}
/**
 * 
 * @export
 * @interface CreatePersonDto
 */
export interface CreatePersonDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'civil_status': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonDto
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonDto
     */
    'is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'activation_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'department': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePersonDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePersonDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreatePoPrDto
 */
export interface CreatePoPrDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePoPrDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePoPrDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePoPrDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePoPrDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePoPrDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePoPrDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePoPrDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePoPrDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreatePrItemDeliveryDto
 */
export interface CreatePrItemDeliveryDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'batch'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'purchase_order'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'purchase_request'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePrItemDeliveryDto
     */
    'quantity': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePrItemDeliveryDto
     */
    'is_tracked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePrItemDeliveryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDeliveryDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreatePrItemDto
 */
export interface CreatePrItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'purchase_request'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'unit': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePrItemDto
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePrItemDto
     */
    'delivered_quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'brand'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePrItemDto
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePrItemDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrItemDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreatePurchaseOrderDto
 */
export interface CreatePurchaseOrderDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'resolution_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'po_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'po_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'mode_of_procurement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'rfq_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'itb_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'supplier'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'contact_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'delivery_location'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'delivery_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'delivery_term'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'payment_term'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseOrderDto
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'iar_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'iar_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'invoice_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'invoice_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'signatory_name_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'signatory_office_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'signatory_name_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'signatory_office_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'end_user_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'end_user_office'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseOrderDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {Array<CreatePoPrDto>}
     * @memberof CreatePurchaseOrderDto
     */
    'purchase_requests': Array<CreatePoPrDto>;
}
/**
 * 
 * @export
 * @interface CreatePurchaseRequestDto
 */
export interface CreatePurchaseRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'pr_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'pr_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'sai_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'sai_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'alobs_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'alobs_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'section'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'is_urgent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'has_ppmp': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'has_activity_design': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'has_app': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'has_pow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'has_bar_chart': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'has_tech_spec': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'has_plan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'has_quit_claim': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePurchaseRequestDto
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'signatory_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseRequestDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {Array<CreatePrItemDto>}
     * @memberof CreatePurchaseRequestDto
     */
    'items': Array<CreatePrItemDto>;
}
/**
 * 
 * @export
 * @interface CreateUtilsBrandDto
 */
export interface CreateUtilsBrandDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsBrandDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsBrandDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsBrandDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUtilsBrandDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsBrandDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsBrandDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsBrandDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsBrandDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateUtilsCategoryDto
 */
export interface CreateUtilsCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsCategoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsCategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsCategoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUtilsCategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsCategoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsCategoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsCategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsCategoryDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateUtilsDepartmentDto
 */
export interface CreateUtilsDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsDepartmentDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsDepartmentDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsDepartmentDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUtilsDepartmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsDepartmentDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsDepartmentDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsDepartmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsDepartmentDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateUtilsPrePoStatusDto
 */
export interface CreateUtilsPrePoStatusDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsPrePoStatusDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsPrePoStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsPrePoStatusDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUtilsPrePoStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsPrePoStatusDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsPrePoStatusDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsPrePoStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsPrePoStatusDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateUtilsStageDto
 */
export interface CreateUtilsStageDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStageDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStageDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStageDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUtilsStageDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsStageDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStageDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsStageDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStageDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateUtilsStatusDto
 */
export interface CreateUtilsStatusDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStatusDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStatusDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUtilsStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsStatusDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStatusDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsStatusDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface CreateUtilsUnitDto
 */
export interface CreateUtilsUnitDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsUnitDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsUnitDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsUnitDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUtilsUnitDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsUnitDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsUnitDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof CreateUtilsUnitDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUtilsUnitDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface DashboardControllerGetStage1ReviewSummary200Response
 */
export interface DashboardControllerGetStage1ReviewSummary200Response {
    /**
     * 
     * @type {number}
     * @memberof DashboardControllerGetStage1ReviewSummary200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetStage1ReviewSummaryDto>}
     * @memberof DashboardControllerGetStage1ReviewSummary200Response
     */
    'data'?: Array<GetStage1ReviewSummaryDto>;
}
/**
 * 
 * @export
 * @interface DashboardControllerGetStage1ReviewSummary200ResponseAllOf
 */
export interface DashboardControllerGetStage1ReviewSummary200ResponseAllOf {
    /**
     * 
     * @type {Array<GetStage1ReviewSummaryDto>}
     * @memberof DashboardControllerGetStage1ReviewSummary200ResponseAllOf
     */
    'data'?: Array<GetStage1ReviewSummaryDto>;
}
/**
 * 
 * @export
 * @interface DashboardControllerGetStage1Summary200Response
 */
export interface DashboardControllerGetStage1Summary200Response {
    /**
     * 
     * @type {number}
     * @memberof DashboardControllerGetStage1Summary200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetStage1SummaryDto>}
     * @memberof DashboardControllerGetStage1Summary200Response
     */
    'data'?: Array<GetStage1SummaryDto>;
}
/**
 * 
 * @export
 * @interface DashboardControllerGetStage1Summary200ResponseAllOf
 */
export interface DashboardControllerGetStage1Summary200ResponseAllOf {
    /**
     * 
     * @type {Array<GetStage1SummaryDto>}
     * @memberof DashboardControllerGetStage1Summary200ResponseAllOf
     */
    'data'?: Array<GetStage1SummaryDto>;
}
/**
 * 
 * @export
 * @interface DashboardControllerPrDashboardReport200Response
 */
export interface DashboardControllerPrDashboardReport200Response {
    /**
     * 
     * @type {number}
     * @memberof DashboardControllerPrDashboardReport200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ListDataDto>}
     * @memberof DashboardControllerPrDashboardReport200Response
     */
    'data'?: Array<ListDataDto>;
}
/**
 * 
 * @export
 * @interface DashboardControllerPrDashboardReport200ResponseAllOf
 */
export interface DashboardControllerPrDashboardReport200ResponseAllOf {
    /**
     * 
     * @type {Array<ListDataDto>}
     * @memberof DashboardControllerPrDashboardReport200ResponseAllOf
     */
    'data'?: Array<ListDataDto>;
}
/**
 * 
 * @export
 * @interface DeleteBidderDto
 */
export interface DeleteBidderDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteBidderDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteBidderDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteBidderDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteBidderDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteBidderDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteBidderDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteInventoryDto
 */
export interface DeleteInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteInventoryDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteInventoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteInventoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteInventoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteInventoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteInventoryDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteInventoryStatusDto
 */
export interface DeleteInventoryStatusDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteInventoryStatusDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteInventoryStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteInventoryStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteInventoryStatusDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteInventoryStatusDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteInventoryStatusDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteItemDto
 */
export interface DeleteItemDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteItemDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteItemDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteItemDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteItemDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteItemDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteItemDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteManualInventoryDto
 */
export interface DeleteManualInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteManualInventoryDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteManualInventoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteManualInventoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteManualInventoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteManualInventoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteManualInventoryDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteNotificationDto
 */
export interface DeleteNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteNotificationDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteNotificationDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteNotificationDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteNotificationDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteNotificationDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteNotificationDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeletePIDDto
 */
export interface DeletePIDDto {
    /**
     * 
     * @type {string}
     * @memberof DeletePIDDto
     */
    'batch': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeletePIDDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePIDDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePIDDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePIDDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePIDDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeletePersonDto
 */
export interface DeletePersonDto {
    /**
     * 
     * @type {string}
     * @memberof DeletePersonDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeletePersonDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePersonDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePersonDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePersonDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeletePoPrDto
 */
export interface DeletePoPrDto {
    /**
     * 
     * @type {string}
     * @memberof DeletePoPrDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeletePoPrDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePoPrDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePoPrDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePoPrDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePoPrDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeletePrItemDto
 */
export interface DeletePrItemDto {
    /**
     * 
     * @type {string}
     * @memberof DeletePrItemDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeletePrItemDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePrItemDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePrItemDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePrItemDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePrItemDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeletePurchaseOrderDto
 */
export interface DeletePurchaseOrderDto {
    /**
     * 
     * @type {string}
     * @memberof DeletePurchaseOrderDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeletePurchaseOrderDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePurchaseOrderDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePurchaseOrderDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePurchaseOrderDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePurchaseOrderDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeletePurchaseRequestDto
 */
export interface DeletePurchaseRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DeletePurchaseRequestDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeletePurchaseRequestDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePurchaseRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePurchaseRequestDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeletePurchaseRequestDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletePurchaseRequestDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteTransactionHistoryDto
 */
export interface DeleteTransactionHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteTransactionHistoryDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteTransactionHistoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteTransactionHistoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteTransactionHistoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteTransactionHistoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteTransactionHistoryDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteUtilsBrandDto
 */
export interface DeleteUtilsBrandDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsBrandDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUtilsBrandDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsBrandDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsBrandDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsBrandDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsBrandDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteUtilsCategoryDto
 */
export interface DeleteUtilsCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsCategoryDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUtilsCategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsCategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsCategoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsCategoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsCategoryDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteUtilsDepartmentDto
 */
export interface DeleteUtilsDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsDepartmentDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUtilsDepartmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsDepartmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsDepartmentDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsDepartmentDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsDepartmentDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteUtilsPrePoStatusDto
 */
export interface DeleteUtilsPrePoStatusDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsPrePoStatusDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUtilsPrePoStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsPrePoStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsPrePoStatusDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsPrePoStatusDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsPrePoStatusDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteUtilsStageDto
 */
export interface DeleteUtilsStageDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsStageDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUtilsStageDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsStageDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsStageDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsStageDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsStageDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteUtilsStatusDto
 */
export interface DeleteUtilsStatusDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsStatusDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUtilsStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsStatusDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsStatusDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsStatusDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface DeleteUtilsUnitDto
 */
export interface DeleteUtilsUnitDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsUnitDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUtilsUnitDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsUnitDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsUnitDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof DeleteUtilsUnitDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUtilsUnitDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface EditBidderDto
 */
export interface EditBidderDto {
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'tin'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditBidderDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditBidderDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBidderDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditInventoryDto
 */
export interface EditInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'inventory_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'property_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'iar_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'date_assigned'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'office'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'building'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditInventoryDto
     */
    'end_of_life'?: number;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditInventoryDto
     */
    'is_active': boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditInventoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditInventoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditInventoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'par_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryDto
     */
    'ics_no'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditInventoryDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface EditInventoryStatusDto
 */
export interface EditInventoryStatusDto {
    /**
     * 
     * @type {string}
     * @memberof EditInventoryStatusDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryStatusDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditInventoryStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditInventoryStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInventoryStatusDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditItemDto
 */
export interface EditItemDto {
    /**
     * 
     * @type {string}
     * @memberof EditItemDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditItemDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditItemDto
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof EditItemDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof EditItemDto
     */
    'brand'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditItemDto
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof EditItemDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditItemDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditItemDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditManualInventoryDto
 */
export interface EditManualInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'inventory_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'po_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'resolution_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'po_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'po_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'mode_of_procurement'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'supplier'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'supplier_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'supplier_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'supplier_contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'supplier_tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'pr_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'pr_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'pr_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'pr_department'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'pr_section'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'pr_purpose'?: string;
    /**
     * `code` from `item` table.
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'pr_item_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'delivery_brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'delivery_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'property_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'iar_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'date_assigned'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'office'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'building'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditManualInventoryDto
     */
    'end_of_life'?: number;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditManualInventoryDto
     */
    'is_active': boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'par_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditManualInventoryDto
     */
    'ics_no'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditManualInventoryDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface EditPIDDto
 */
export interface EditPIDDto {
    /**
     * 
     * @type {Array<EditPrItemDeliveryDto>}
     * @memberof EditPIDDto
     */
    'delivery'?: Array<EditPrItemDeliveryDto>;
}
/**
 * 
 * @export
 * @interface EditPersonDto
 */
export interface EditPersonDto {
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'civil_status': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPersonDto
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPersonDto
     */
    'is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'activation_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'department': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPersonDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPersonDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditPoPrDto
 */
export interface EditPoPrDto {
    /**
     * 
     * @type {string}
     * @memberof EditPoPrDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPoPrDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof EditPoPrDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPoPrDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPoPrDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPoPrDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPoPrDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPoPrDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPoPrDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPoPrDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface EditPrItemDeliveryDto
 */
export interface EditPrItemDeliveryDto {
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'batch'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditPrItemDeliveryDto
     */
    'quantity': number;
    /**
     * 
     * @type {boolean}
     * @memberof EditPrItemDeliveryDto
     */
    'is_tracked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPrItemDeliveryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDeliveryDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface EditPrItemDto
 */
export interface EditPrItemDto {
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'unit': string;
    /**
     * 
     * @type {number}
     * @memberof EditPrItemDto
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof EditPrItemDto
     */
    'delivered_quantity': number;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'brand'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditPrItemDto
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof EditPrItemDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPrItemDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPrItemDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPrItemDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPrItemDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface EditPurchaseOrderDto
 */
export interface EditPurchaseOrderDto {
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'resolution_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'po_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'po_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'mode_of_procurement'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'rfq_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'itb_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'supplier'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'contact_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'delivery_location'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'delivery_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'delivery_term'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'payment_term'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseOrderDto
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'iar_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'iar_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'invoice_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'invoice_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'signatory_name_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'signatory_office_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'signatory_name_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'signatory_office_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'end_user_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'end_user_office'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {Array<EditPoPrDto>}
     * @memberof EditPurchaseOrderDto
     */
    'purchase_requests': Array<EditPoPrDto>;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseOrderDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface EditPurchaseRequestDto
 */
export interface EditPurchaseRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'pr_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'pr_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'sai_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'sai_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'alobs_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'alobs_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'section'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'is_urgent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'has_ppmp': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'has_activity_design': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'has_app': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'has_pow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'has_bar_chart': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'has_tech_spec': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'has_plan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'has_quit_claim': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'signatory_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {Array<EditPrItemDto>}
     * @memberof EditPurchaseRequestDto
     */
    'items'?: Array<EditPrItemDto>;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'is_gso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'gso_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'gso_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'gso_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'is_treasurer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'treasurer_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'treasurer_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'treasurer_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'is_mayor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'mayor_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'mayor_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'mayor_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'is_gso_ff'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'gso_ff_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'gso_ff_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'gso_ff_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPurchaseRequestDto
     */
    'is_budget'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'budget_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'budget_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPurchaseRequestDto
     */
    'budget_at': string;
}
/**
 * 
 * @export
 * @interface EditUtilsBrandDto
 */
export interface EditUtilsBrandDto {
    /**
     * 
     * @type {string}
     * @memberof EditUtilsBrandDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsBrandDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsBrandDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUtilsBrandDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditUtilsBrandDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsBrandDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditUtilsCategoryDto
 */
export interface EditUtilsCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof EditUtilsCategoryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsCategoryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsCategoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUtilsCategoryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditUtilsCategoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsCategoryDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditUtilsDepartmentDto
 */
export interface EditUtilsDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof EditUtilsDepartmentDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsDepartmentDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsDepartmentDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUtilsDepartmentDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditUtilsDepartmentDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsDepartmentDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditUtilsPrePoStatusDto
 */
export interface EditUtilsPrePoStatusDto {
    /**
     * 
     * @type {string}
     * @memberof EditUtilsPrePoStatusDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsPrePoStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsPrePoStatusDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUtilsPrePoStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditUtilsPrePoStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsPrePoStatusDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditUtilsStageDto
 */
export interface EditUtilsStageDto {
    /**
     * 
     * @type {string}
     * @memberof EditUtilsStageDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsStageDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsStageDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUtilsStageDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditUtilsStageDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsStageDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditUtilsStatusDto
 */
export interface EditUtilsStatusDto {
    /**
     * 
     * @type {string}
     * @memberof EditUtilsStatusDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsStatusDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUtilsStatusDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditUtilsStatusDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsStatusDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface EditUtilsUnitDto
 */
export interface EditUtilsUnitDto {
    /**
     * 
     * @type {string}
     * @memberof EditUtilsUnitDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsUnitDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsUnitDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUtilsUnitDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof EditUtilsUnitDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUtilsUnitDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordDto
 */
export interface ForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface GetBatchDeliveryDto
 */
export interface GetBatchDeliveryDto {
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'batch'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'item_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'brand_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'unit_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'unit_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBatchDeliveryDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchDeliveryDto
     */
    'quantity': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetBatchDeliveryDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchDeliveryDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface GetBidderDto
 */
export interface GetBidderDto {
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'tin'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBidderDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetBidderDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBidderDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface GetInventoryDto
 */
export interface GetInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'batch'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'inventory_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'po_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'resolution_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetInventoryDto
     */
    'po_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'po_category': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'po_category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'mode_of_procurement'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'supplier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'supplier_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'supplier_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'supplier_contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'supplier_tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_category': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_department': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_section'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'pr_item_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'item_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'item_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetInventoryDto
     */
    'item_price': number;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'unit_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'unit_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'delivery_brand': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'brand_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'brand_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'delivery_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'property_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'iar_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'assignee'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetInventoryDto
     */
    'date_assigned'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'lot': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'office': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'building': string;
    /**
     * 
     * @type {number}
     * @memberof GetInventoryDto
     */
    'end_of_life': number;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'status_name': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetInventoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'create_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'create_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetInventoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'update_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'update_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetInventoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'par_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInventoryDto
     */
    'ics_no'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetInventoryDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface GetItemDto
 */
export interface GetItemDto {
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'unit_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'brand_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetItemDto
     */
    'price': number;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetItemDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetItemDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetItemDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface GetManualInventoryDto
 */
export interface GetManualInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'batch'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'inventory_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'po_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'resolution_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'po_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'po_category': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'po_category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'mode_of_procurement'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'supplier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'supplier_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'supplier_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'supplier_contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'supplier_tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_category': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_department': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_section'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'pr_item_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'item_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'item_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetManualInventoryDto
     */
    'item_price': number;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'unit_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'unit_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'delivery_brand': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'brand_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'brand_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'delivery_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'property_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'iar_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'assignee'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'date_assigned'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'lot': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'office': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'building': string;
    /**
     * 
     * @type {number}
     * @memberof GetManualInventoryDto
     */
    'end_of_life': number;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'status_name': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'create_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'create_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'update_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'update_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'par_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManualInventoryDto
     */
    'ics_no'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetManualInventoryDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface GetNotificationDto
 */
export interface GetNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    'request_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    'request_source': string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    'message'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetNotificationDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetNotificationDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface GetPIDDto
 */
export interface GetPIDDto {
    /**
     * 
     * @type {string}
     * @memberof GetPIDDto
     */
    'batch'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPIDDto
     */
    'purchase_order'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPIDDto
     */
    'purchase_request'?: string;
    /**
     * 
     * @type {Array<GetBatchDeliveryDto>}
     * @memberof GetPIDDto
     */
    'delivery'?: Array<GetBatchDeliveryDto>;
    /**
     * 
     * @type {number}
     * @memberof GetPIDDto
     */
    'total_quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPIDDto
     */
    'total_amount'?: number;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPIDDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPIDDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPIDDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPIDDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPIDDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPIDDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface GetPersonDto
 */
export interface GetPersonDto {
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_username': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_position'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_gender': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_civil_status': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_province'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_region'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_email': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonDto
     */
    'person_is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonDto
     */
    'person_is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'person_avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'role_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'role_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'department_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'department_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'updated_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPersonDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    'deleted_by'?: string;
}
/**
 * 
 * @export
 * @interface GetPoPrDto
 */
export interface GetPoPrDto {
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'purchase_request': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPoPrDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'create_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'create_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPoPrDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'update_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'update_by_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'pr_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPoPrDto
     */
    'pr_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'sai_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPoPrDto
     */
    'sai_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'alobs_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPoPrDto
     */
    'alobs_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'section'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'status_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'is_urgent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'has_ppmp': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'has_activity_design': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'has_app': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'has_pow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'has_bar_chart': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'has_tech_spec': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'has_plan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPoPrDto
     */
    'has_quit_claim': boolean;
    /**
     * 
     * @type {Array<GetPrItemDto>}
     * @memberof GetPoPrDto
     */
    'items'?: Array<GetPrItemDto>;
    /**
     * 
     * @type {number}
     * @memberof GetPoPrDto
     */
    'total_item'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPoPrDto
     */
    'total_quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPoPrDto
     */
    'total_delivered_quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPoPrDto
     */
    'per_item_cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPoPrDto
     */
    'total_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPoPrDto
     */
    'total_delivered_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoPrDto
     */
    'signatory_name'?: string;
}
/**
 * 
 * @export
 * @interface GetPrItemDeliveryDto
 */
export interface GetPrItemDeliveryDto {
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'batch'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'purchase_order': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'item_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'brand_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrItemDeliveryDto
     */
    'quantity': number;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDeliveryDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface GetPrItemDto
 */
export interface GetPrItemDto {
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'purchase_request': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'item_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'unit_name': string;
    /**
     * 
     * @type {number}
     * @memberof GetPrItemDto
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof GetPrItemDto
     */
    'delivered_quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'brand_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrItemDto
     */
    'price': number;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPrItemDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPrItemDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrItemDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface GetPurchaseOrderDto
 */
export interface GetPurchaseOrderDto {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'resolution_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'mode_of_procurement'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'rfq_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'itb_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'supplier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'contact_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'tin'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'delivery_location'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'delivery_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'delivery_term'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'payment_term'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'status_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseOrderDto
     */
    'is_gso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'gso'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'gso_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'gso_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'gso_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'gso_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'gso_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseOrderDto
     */
    'is_treasurer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'treasurer'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'treasurer_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'treasurer_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'treasurer_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'treasurer_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'treasurer_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseOrderDto
     */
    'is_mayor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'mayor'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'mayor_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'mayor_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'mayor_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'mayor_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'mayor_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'reviewer'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'iar_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'iar_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'invoice_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'invoice_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'signatory_name_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'signatory_office_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'signatory_name_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'signatory_office_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'end_user_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'end_user_office'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'create_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'create_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'update_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'update_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {Array<GetPurchaseRequestDto>}
     * @memberof GetPurchaseOrderDto
     */
    'purchase_requests'?: Array<GetPurchaseRequestDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseOrderDto
     */
    'po_is_gso_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_gso_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_gso_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_gso_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_gso_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseOrderDto
     */
    'po_is_treasurer_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_treasurer_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_treasurer_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_treasurer_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_treasurer_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseOrderDto
     */
    'po_is_mayor_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_mayor_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_mayor_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_mayor_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseOrderDto
     */
    'po_mayor_received_at'?: string;
}
/**
 * 
 * @export
 * @interface GetPurchaseRequestDto
 */
export interface GetPurchaseRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_pr_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'pr_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'pr_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'sai_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'sai_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'alobs_no'?: string;
    /**
     * The date of the event. Format: YYYY-MM-DD
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'alobs_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'department_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'department_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'section'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'status_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_urgent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'has_ppmp': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'has_activity_design': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'has_app': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'has_pow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'has_bar_chart': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'has_tech_spec': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'has_plan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'has_quit_claim': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_gso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_treasurer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_mayor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_gso_ff'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_budget'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_at'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'create_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'create_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'updated_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'update_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'update_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'deleted_by'?: string;
    /**
     * 
     * @type {Array<GetPrItemDto>}
     * @memberof GetPurchaseRequestDto
     */
    'items'?: Array<GetPrItemDto>;
    /**
     * 
     * @type {Array<GetPIDDto>}
     * @memberof GetPurchaseRequestDto
     */
    'delivery'?: Array<GetPIDDto>;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'stage_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'stage_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'stage_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'reviewer'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseRequestDto
     */
    'total_item'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseRequestDto
     */
    'total_quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseRequestDto
     */
    'total_delivered_quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseRequestDto
     */
    'per_item_cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseRequestDto
     */
    'total_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseRequestDto
     */
    'total_delivered_amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'po_is_gso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'po_is_treasurer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'po_is_mayor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'signatory_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'decline_remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'approve_remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_gso_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_treasurer_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'treasurer_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_mayor_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'mayor_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_gso_ff_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'gso_ff_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_budget_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'budget_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'is_bac_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'bac_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'bac_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'bac_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'bac_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'po_is_gso_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_gso_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'po_is_treasurer_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_treasurer_received_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseRequestDto
     */
    'po_is_mayor_received'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_received_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_received_by_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_received_by_last_name'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetPurchaseRequestDto
     */
    'po_mayor_received_at'?: string;
}
/**
 * 
 * @export
 * @interface GetStage1ReviewSummaryDto
 */
export interface GetStage1ReviewSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof GetStage1ReviewSummaryDto
     */
    'approver': string;
    /**
     * 
     * @type {number}
     * @memberof GetStage1ReviewSummaryDto
     */
    'tally': number;
}
/**
 * 
 * @export
 * @interface GetStage1SummaryDto
 */
export interface GetStage1SummaryDto {
    /**
     * 
     * @type {string}
     * @memberof GetStage1SummaryDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetStage1SummaryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetStage1SummaryDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof GetStage1SummaryDto
     */
    'tally': number;
}
/**
 * 
 * @export
 * @interface GetTransactionHistoryDto
 */
export interface GetTransactionHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'request_code': string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'request_source': string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'new_values'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'old_values'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'description'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionHistoryDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface InventoryControllerGetDataAsList200Response
 */
export interface InventoryControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof InventoryControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetInventoryDto>}
     * @memberof InventoryControllerGetDataAsList200Response
     */
    'data'?: Array<GetInventoryDto>;
}
/**
 * 
 * @export
 * @interface InventoryControllerGetDataAsList200ResponseAllOf
 */
export interface InventoryControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetInventoryDto>}
     * @memberof InventoryControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetInventoryDto>;
}
/**
 * 
 * @export
 * @interface ItemControllerGetDataAsList200Response
 */
export interface ItemControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof ItemControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetItemDto>}
     * @memberof ItemControllerGetDataAsList200Response
     */
    'data'?: Array<GetItemDto>;
}
/**
 * 
 * @export
 * @interface ItemControllerGetDataAsList200ResponseAllOf
 */
export interface ItemControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetItemDto>}
     * @memberof ItemControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetItemDto>;
}
/**
 * 
 * @export
 * @interface ListDataDto
 */
export interface ListDataDto {
    /**
     * 
     * @type {string}
     * @memberof ListDataDto
     */
    'value': string;
    /**
     * 
     * @type {Any}
     * @memberof ListDataDto
     */
    'label': any;
}
/**
 * 
 * @export
 * @interface LoginPersonDto
 */
export interface LoginPersonDto {
    /**
     * 
     * @type {string}
     * @memberof LoginPersonDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginPersonDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_code': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_position'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_gender': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_civil_status': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_street_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_sitio'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_purok'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_barangay'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_municipality'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_province'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_region'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_country'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponseDto
     */
    'person_is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponseDto
     */
    'person_is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'person_avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'oauth_client_secret': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'oauth_client_scope'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'oauth_client_grant_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'oauth_token': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'oauth_refresh_token': string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof LoginResponseDto
     */
    'oauth_expiry': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'role_code': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'role_name': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'role_description': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'department_code': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'department_name': string;
}
/**
 * 
 * @export
 * @interface ManualInventoryControllerGetDataAsList200Response
 */
export interface ManualInventoryControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof ManualInventoryControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetManualInventoryDto>}
     * @memberof ManualInventoryControllerGetDataAsList200Response
     */
    'data'?: Array<GetManualInventoryDto>;
}
/**
 * 
 * @export
 * @interface ManualInventoryControllerGetDataAsList200ResponseAllOf
 */
export interface ManualInventoryControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetManualInventoryDto>}
     * @memberof ManualInventoryControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetManualInventoryDto>;
}
/**
 * 
 * @export
 * @interface MessageResponseDto
 */
export interface MessageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof MessageResponseDto
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface MonitorDto
 */
export interface MonitorDto {
    /**
     * PR Item Delivery Code
     * @type {string}
     * @memberof MonitorDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitorDto
     */
    'is_bulk'?: boolean;
}
/**
 * 
 * @export
 * @interface MonitorPIDDto
 */
export interface MonitorPIDDto {
    /**
     * PR Item Delivery Batch Code
     * @type {string}
     * @memberof MonitorPIDDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitorPIDDto
     */
    'is_tracked'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof MonitorPIDDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorPIDDto
     */
    'updated_by'?: string;
}
/**
 * 
 * @export
 * @interface NotificationControllerGetDataAsList200Response
 */
export interface NotificationControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof NotificationControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetNotificationDto>}
     * @memberof NotificationControllerGetDataAsList200Response
     */
    'data'?: Array<GetNotificationDto>;
}
/**
 * 
 * @export
 * @interface NotificationControllerGetDataAsList200ResponseAllOf
 */
export interface NotificationControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetNotificationDto>}
     * @memberof NotificationControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetNotificationDto>;
}
/**
 * 
 * @export
 * @interface PersonControllerGetDataAsList200Response
 */
export interface PersonControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof PersonControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPersonDto>}
     * @memberof PersonControllerGetDataAsList200Response
     */
    'data'?: Array<GetPersonDto>;
}
/**
 * 
 * @export
 * @interface PersonControllerGetDataAsList200ResponseAllOf
 */
export interface PersonControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetPersonDto>}
     * @memberof PersonControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetPersonDto>;
}
/**
 * 
 * @export
 * @interface PoPrControllerGetDataAsList200Response
 */
export interface PoPrControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof PoPrControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPoPrDto>}
     * @memberof PoPrControllerGetDataAsList200Response
     */
    'data'?: Array<GetPoPrDto>;
}
/**
 * 
 * @export
 * @interface PoPrControllerGetDataAsList200ResponseAllOf
 */
export interface PoPrControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetPoPrDto>}
     * @memberof PoPrControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetPoPrDto>;
}
/**
 * 
 * @export
 * @interface PrItemControllerGetDataAsList200Response
 */
export interface PrItemControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof PrItemControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPrItemDto>}
     * @memberof PrItemControllerGetDataAsList200Response
     */
    'data'?: Array<GetPrItemDto>;
}
/**
 * 
 * @export
 * @interface PrItemControllerGetDataAsList200ResponseAllOf
 */
export interface PrItemControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetPrItemDto>}
     * @memberof PrItemControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetPrItemDto>;
}
/**
 * 
 * @export
 * @interface PrItemDeliveryControllerGetDataAsList200Response
 */
export interface PrItemDeliveryControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof PrItemDeliveryControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPrItemDeliveryDto>}
     * @memberof PrItemDeliveryControllerGetDataAsList200Response
     */
    'data'?: Array<GetPrItemDeliveryDto>;
}
/**
 * 
 * @export
 * @interface PrItemDeliveryControllerGetDataAsList200ResponseAllOf
 */
export interface PrItemDeliveryControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetPrItemDeliveryDto>}
     * @memberof PrItemDeliveryControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetPrItemDeliveryDto>;
}
/**
 * 
 * @export
 * @interface ProcessPurchaseOrderDto
 */
export interface ProcessPurchaseOrderDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessPurchaseOrderDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPurchaseOrderDto
     */
    'is_gso'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPurchaseOrderDto
     */
    'is_treasurer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPurchaseOrderDto
     */
    'is_mayor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessPurchaseOrderDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface ProcessPurchaseRequestDto
 */
export interface ProcessPurchaseRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessPurchaseRequestDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPurchaseRequestDto
     */
    'is_gso'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPurchaseRequestDto
     */
    'is_treasurer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPurchaseRequestDto
     */
    'is_mayor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPurchaseRequestDto
     */
    'is_gso_ff'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPurchaseRequestDto
     */
    'is_budget'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessPurchaseRequestDto
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface PurchaseOrderControllerGetDataAsList200Response
 */
export interface PurchaseOrderControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPurchaseOrderDto>}
     * @memberof PurchaseOrderControllerGetDataAsList200Response
     */
    'data'?: Array<GetPurchaseOrderDto>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderControllerGetDataAsList200ResponseAllOf
 */
export interface PurchaseOrderControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetPurchaseOrderDto>}
     * @memberof PurchaseOrderControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetPurchaseOrderDto>;
}
/**
 * 
 * @export
 * @interface PurchaseRequestControllerGetDataAsList200Response
 */
export interface PurchaseRequestControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequestControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetPurchaseRequestDto>}
     * @memberof PurchaseRequestControllerGetDataAsList200Response
     */
    'data'?: Array<GetPurchaseRequestDto>;
}
/**
 * 
 * @export
 * @interface PurchaseRequestControllerGetDataAsList200ResponseAllOf
 */
export interface PurchaseRequestControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetPurchaseRequestDto>}
     * @memberof PurchaseRequestControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetPurchaseRequestDto>;
}
/**
 * 
 * @export
 * @interface ReceivePurchaseOrderDto
 */
export interface ReceivePurchaseOrderDto {
    /**
     * 
     * @type {string}
     * @memberof ReceivePurchaseOrderDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseOrderDto
     */
    'is_gso_received'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseOrderDto
     */
    'is_treasurer_received'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseOrderDto
     */
    'is_mayor_received'?: boolean;
}
/**
 * 
 * @export
 * @interface ReceivePurchaseRequestDto
 */
export interface ReceivePurchaseRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ReceivePurchaseRequestDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseRequestDto
     */
    'is_gso_received'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseRequestDto
     */
    'is_treasurer_received'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseRequestDto
     */
    'is_mayor_received'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseRequestDto
     */
    'is_gso_ff_received'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseRequestDto
     */
    'is_budget_received'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivePurchaseRequestDto
     */
    'is_bac_received'?: boolean;
}
/**
 * 
 * @export
 * @interface TransactionHistoryControllerGetDataAsList200Response
 */
export interface TransactionHistoryControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof TransactionHistoryControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetTransactionHistoryDto>}
     * @memberof TransactionHistoryControllerGetDataAsList200Response
     */
    'data'?: Array<GetTransactionHistoryDto>;
}
/**
 * 
 * @export
 * @interface TransactionHistoryControllerGetDataAsList200ResponseAllOf
 */
export interface TransactionHistoryControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<GetTransactionHistoryDto>}
     * @memberof TransactionHistoryControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<GetTransactionHistoryDto>;
}
/**
 * 
 * @export
 * @interface UpdatePersonDto
 */
export interface UpdatePersonDto {
    /**
     * 
     * @type {EditPersonDto}
     * @memberof UpdatePersonDto
     */
    'person': EditPersonDto;
    /**
     * 
     * @type {any}
     * @memberof UpdatePersonDto
     */
    'file'?: any;
}
/**
 * 
 * @export
 * @interface UtilsBrandControllerGetDataAsList200Response
 */
export interface UtilsBrandControllerGetDataAsList200Response {
    /**
     * 
     * @type {number}
     * @memberof UtilsBrandControllerGetDataAsList200Response
     */
    'count'?: number;
    /**
     * 
     * @type {Array<UtilsDataDto>}
     * @memberof UtilsBrandControllerGetDataAsList200Response
     */
    'data'?: Array<UtilsDataDto>;
}
/**
 * 
 * @export
 * @interface UtilsBrandControllerGetDataAsList200ResponseAllOf
 */
export interface UtilsBrandControllerGetDataAsList200ResponseAllOf {
    /**
     * 
     * @type {Array<UtilsDataDto>}
     * @memberof UtilsBrandControllerGetDataAsList200ResponseAllOf
     */
    'data'?: Array<UtilsDataDto>;
}
/**
 * 
 * @export
 * @interface UtilsDataDto
 */
export interface UtilsDataDto {
    /**
     * 
     * @type {string}
     * @memberof UtilsDataDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UtilsDataDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UtilsDataDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UtilsDataDto
     */
    'is_active'?: boolean;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof UtilsDataDto
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilsDataDto
     */
    'created_by'?: string;
    /**
     * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
     * @type {string}
     * @memberof UtilsDataDto
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilsDataDto
     */
    'updated_by'?: string;
}

/**
 * BidderApi - axios parameter creator
 * @export
 */
export const BidderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBidderDto} createBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderControllerCreate: async (createBidderDto: CreateBidderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBidderDto' is not null or undefined
            assertParamExists('bidderControllerCreate', 'createBidderDto', createBidderDto)
            const localVarPath = `/api/v1/bidder/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBidderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteBidderDto} deleteBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderControllerDelete: async (deleteBidderDto: DeleteBidderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteBidderDto' is not null or undefined
            assertParamExists('bidderControllerDelete', 'deleteBidderDto', deleteBidderDto)
            const localVarPath = `/api/v1/bidder/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBidderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditBidderDto} editBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderControllerEdit: async (editBidderDto: EditBidderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editBidderDto' is not null or undefined
            assertParamExists('bidderControllerEdit', 'editBidderDto', editBidderDto)
            const localVarPath = `/api/v1/bidder/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editBidderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bidder/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BidderApi - functional programming interface
 * @export
 */
export const BidderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BidderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBidderDto} createBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bidderControllerCreate(createBidderDto: CreateBidderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bidderControllerCreate(createBidderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteBidderDto} deleteBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bidderControllerDelete(deleteBidderDto: DeleteBidderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bidderControllerDelete(deleteBidderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditBidderDto} editBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bidderControllerEdit(editBidderDto: EditBidderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bidderControllerEdit(editBidderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bidderControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidderControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bidderControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BidderApi - factory interface
 * @export
 */
export const BidderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BidderApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBidderDto} createBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderControllerCreate(createBidderDto: CreateBidderDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.bidderControllerCreate(createBidderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteBidderDto} deleteBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderControllerDelete(deleteBidderDto: DeleteBidderDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.bidderControllerDelete(deleteBidderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditBidderDto} editBidderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderControllerEdit(editBidderDto: EditBidderDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.bidderControllerEdit(editBidderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<BidderControllerGetDataAsList200Response> {
            return localVarFp.bidderControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BidderApi - object-oriented interface
 * @export
 * @class BidderApi
 * @extends {BaseAPI}
 */
export class BidderApi extends BaseAPI {
    /**
     * 
     * @param {CreateBidderDto} createBidderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidderApi
     */
    public bidderControllerCreate(createBidderDto: CreateBidderDto, options?: AxiosRequestConfig) {
        return BidderApiFp(this.configuration).bidderControllerCreate(createBidderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteBidderDto} deleteBidderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidderApi
     */
    public bidderControllerDelete(deleteBidderDto: DeleteBidderDto, options?: AxiosRequestConfig) {
        return BidderApiFp(this.configuration).bidderControllerDelete(deleteBidderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditBidderDto} editBidderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidderApi
     */
    public bidderControllerEdit(editBidderDto: EditBidderDto, options?: AxiosRequestConfig) {
        return BidderApiFp(this.configuration).bidderControllerEdit(editBidderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidderApi
     */
    public bidderControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return BidderApiFp(this.configuration).bidderControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetRequestorJourneySummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/requestor-journey-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage1ReviewSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/stage-1-review-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage1Summary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/stage-1-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage2Summary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/stage-2-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage3ReviewSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/stage-3-review-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage3Summary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/stage-3-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage4Summary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/stage-4-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerInventoryDashboardReport: async (filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/inventory-dashboard-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerPoDashboardReport: async (filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/purchase-order-dashboard-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerPrDashboardReport: async (filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/purchase-request-dashboard-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetRequestorJourneySummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerGetStage1Summary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetRequestorJourneySummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetStage1ReviewSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerGetStage1ReviewSummary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetStage1ReviewSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetStage1Summary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerGetStage1Summary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetStage1Summary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetStage2Summary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerGetStage1Summary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetStage2Summary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetStage3ReviewSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerGetStage1ReviewSummary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetStage3ReviewSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetStage3Summary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerGetStage1Summary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetStage3Summary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetStage4Summary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerGetStage1Summary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetStage4Summary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerInventoryDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerPrDashboardReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerInventoryDashboardReport(filter, dateFilter, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerPoDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerPrDashboardReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerPoDashboardReport(filter, dateFilter, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerPrDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardControllerPrDashboardReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerPrDashboardReport(filter, dateFilter, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetRequestorJourneySummary(options?: any): AxiosPromise<DashboardControllerGetStage1Summary200Response> {
            return localVarFp.dashboardControllerGetRequestorJourneySummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage1ReviewSummary(options?: any): AxiosPromise<DashboardControllerGetStage1ReviewSummary200Response> {
            return localVarFp.dashboardControllerGetStage1ReviewSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage1Summary(options?: any): AxiosPromise<DashboardControllerGetStage1Summary200Response> {
            return localVarFp.dashboardControllerGetStage1Summary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage2Summary(options?: any): AxiosPromise<DashboardControllerGetStage1Summary200Response> {
            return localVarFp.dashboardControllerGetStage2Summary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage3ReviewSummary(options?: any): AxiosPromise<DashboardControllerGetStage1ReviewSummary200Response> {
            return localVarFp.dashboardControllerGetStage3ReviewSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage3Summary(options?: any): AxiosPromise<DashboardControllerGetStage1Summary200Response> {
            return localVarFp.dashboardControllerGetStage3Summary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetStage4Summary(options?: any): AxiosPromise<DashboardControllerGetStage1Summary200Response> {
            return localVarFp.dashboardControllerGetStage4Summary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerInventoryDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<DashboardControllerPrDashboardReport200Response> {
            return localVarFp.dashboardControllerInventoryDashboardReport(filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerPoDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<DashboardControllerPrDashboardReport200Response> {
            return localVarFp.dashboardControllerPoDashboardReport(filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerPrDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<DashboardControllerPrDashboardReport200Response> {
            return localVarFp.dashboardControllerPrDashboardReport(filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetRequestorJourneySummary(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetRequestorJourneySummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetStage1ReviewSummary(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetStage1ReviewSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetStage1Summary(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetStage1Summary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetStage2Summary(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetStage2Summary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetStage3ReviewSummary(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetStage3ReviewSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetStage3Summary(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetStage3Summary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetStage4Summary(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetStage4Summary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerInventoryDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerInventoryDashboardReport(filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerPoDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerPoDashboardReport(filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerPrDashboardReport(filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerPrDashboardReport(filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryApi - axios parameter creator
 * @export
 */
export const InventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateInventoryDto} createInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryControllerCreate: async (createInventoryDto: CreateInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInventoryDto' is not null or undefined
            assertParamExists('inventoryControllerCreate', 'createInventoryDto', createInventoryDto)
            const localVarPath = `/api/v1/inventory/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteInventoryDto} deleteInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryControllerDelete: async (deleteInventoryDto: DeleteInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteInventoryDto' is not null or undefined
            assertParamExists('inventoryControllerDelete', 'deleteInventoryDto', deleteInventoryDto)
            const localVarPath = `/api/v1/inventory/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditInventoryDto} editInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryControllerEdit: async (editInventoryDto: EditInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editInventoryDto' is not null or undefined
            assertParamExists('inventoryControllerEdit', 'editInventoryDto', editInventoryDto)
            const localVarPath = `/api/v1/inventory/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryApi - functional programming interface
 * @export
 */
export const InventoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateInventoryDto} createInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryControllerCreate(createInventoryDto: CreateInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryControllerCreate(createInventoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteInventoryDto} deleteInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryControllerDelete(deleteInventoryDto: DeleteInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryControllerDelete(deleteInventoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditInventoryDto} editInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryControllerEdit(editInventoryDto: EditInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryControllerEdit(editInventoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryApi - factory interface
 * @export
 */
export const InventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateInventoryDto} createInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryControllerCreate(createInventoryDto: CreateInventoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.inventoryControllerCreate(createInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteInventoryDto} deleteInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryControllerDelete(deleteInventoryDto: DeleteInventoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.inventoryControllerDelete(deleteInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditInventoryDto} editInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryControllerEdit(editInventoryDto: EditInventoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.inventoryControllerEdit(editInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<InventoryControllerGetDataAsList200Response> {
            return localVarFp.inventoryControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryApi - object-oriented interface
 * @export
 * @class InventoryApi
 * @extends {BaseAPI}
 */
export class InventoryApi extends BaseAPI {
    /**
     * 
     * @param {CreateInventoryDto} createInventoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public inventoryControllerCreate(createInventoryDto: CreateInventoryDto, options?: AxiosRequestConfig) {
        return InventoryApiFp(this.configuration).inventoryControllerCreate(createInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteInventoryDto} deleteInventoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public inventoryControllerDelete(deleteInventoryDto: DeleteInventoryDto, options?: AxiosRequestConfig) {
        return InventoryApiFp(this.configuration).inventoryControllerDelete(deleteInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditInventoryDto} editInventoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public inventoryControllerEdit(editInventoryDto: EditInventoryDto, options?: AxiosRequestConfig) {
        return InventoryApiFp(this.configuration).inventoryControllerEdit(editInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public inventoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return InventoryApiFp(this.configuration).inventoryControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryStatusApi - axios parameter creator
 * @export
 */
export const InventoryStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateInventoryStatusDto} createInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatusControllerCreate: async (createInventoryStatusDto: CreateInventoryStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInventoryStatusDto' is not null or undefined
            assertParamExists('inventoryStatusControllerCreate', 'createInventoryStatusDto', createInventoryStatusDto)
            const localVarPath = `/api/v1/inventory-status/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInventoryStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteInventoryStatusDto} deleteInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatusControllerDelete: async (deleteInventoryStatusDto: DeleteInventoryStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteInventoryStatusDto' is not null or undefined
            assertParamExists('inventoryStatusControllerDelete', 'deleteInventoryStatusDto', deleteInventoryStatusDto)
            const localVarPath = `/api/v1/inventory-status/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteInventoryStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditInventoryStatusDto} editInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatusControllerEdit: async (editInventoryStatusDto: EditInventoryStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editInventoryStatusDto' is not null or undefined
            assertParamExists('inventoryStatusControllerEdit', 'editInventoryStatusDto', editInventoryStatusDto)
            const localVarPath = `/api/v1/inventory-status/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editInventoryStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatusControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory-status/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryStatusApi - functional programming interface
 * @export
 */
export const InventoryStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateInventoryStatusDto} createInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryStatusControllerCreate(createInventoryStatusDto: CreateInventoryStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryStatusControllerCreate(createInventoryStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteInventoryStatusDto} deleteInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryStatusControllerDelete(deleteInventoryStatusDto: DeleteInventoryStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryStatusControllerDelete(deleteInventoryStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditInventoryStatusDto} editInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryStatusControllerEdit(editInventoryStatusDto: EditInventoryStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryStatusControllerEdit(editInventoryStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryStatusControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryStatusApi - factory interface
 * @export
 */
export const InventoryStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryStatusApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateInventoryStatusDto} createInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatusControllerCreate(createInventoryStatusDto: CreateInventoryStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.inventoryStatusControllerCreate(createInventoryStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteInventoryStatusDto} deleteInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatusControllerDelete(deleteInventoryStatusDto: DeleteInventoryStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.inventoryStatusControllerDelete(deleteInventoryStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditInventoryStatusDto} editInventoryStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatusControllerEdit(editInventoryStatusDto: EditInventoryStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.inventoryStatusControllerEdit(editInventoryStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.inventoryStatusControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryStatusApi - object-oriented interface
 * @export
 * @class InventoryStatusApi
 * @extends {BaseAPI}
 */
export class InventoryStatusApi extends BaseAPI {
    /**
     * 
     * @param {CreateInventoryStatusDto} createInventoryStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryStatusApi
     */
    public inventoryStatusControllerCreate(createInventoryStatusDto: CreateInventoryStatusDto, options?: AxiosRequestConfig) {
        return InventoryStatusApiFp(this.configuration).inventoryStatusControllerCreate(createInventoryStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteInventoryStatusDto} deleteInventoryStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryStatusApi
     */
    public inventoryStatusControllerDelete(deleteInventoryStatusDto: DeleteInventoryStatusDto, options?: AxiosRequestConfig) {
        return InventoryStatusApiFp(this.configuration).inventoryStatusControllerDelete(deleteInventoryStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditInventoryStatusDto} editInventoryStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryStatusApi
     */
    public inventoryStatusControllerEdit(editInventoryStatusDto: EditInventoryStatusDto, options?: AxiosRequestConfig) {
        return InventoryStatusApiFp(this.configuration).inventoryStatusControllerEdit(editInventoryStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryStatusApi
     */
    public inventoryStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return InventoryStatusApiFp(this.configuration).inventoryStatusControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemApi - axios parameter creator
 * @export
 */
export const ItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkItemsDto} bulkItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerBulk: async (bulkItemsDto: BulkItemsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkItemsDto' is not null or undefined
            assertParamExists('itemControllerBulk', 'bulkItemsDto', bulkItemsDto)
            const localVarPath = `/api/v1/item/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkItemsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateItemDto} createItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerCreate: async (createItemDto: CreateItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemDto' is not null or undefined
            assertParamExists('itemControllerCreate', 'createItemDto', createItemDto)
            const localVarPath = `/api/v1/item/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteItemDto} deleteItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerDelete: async (deleteItemDto: DeleteItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteItemDto' is not null or undefined
            assertParamExists('itemControllerDelete', 'deleteItemDto', deleteItemDto)
            const localVarPath = `/api/v1/item/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditItemDto} editItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerEdit: async (editItemDto: EditItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editItemDto' is not null or undefined
            assertParamExists('itemControllerEdit', 'editItemDto', editItemDto)
            const localVarPath = `/api/v1/item/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/item/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemApi - functional programming interface
 * @export
 */
export const ItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BulkItemsDto} bulkItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemControllerBulk(bulkItemsDto: BulkItemsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemControllerBulk(bulkItemsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateItemDto} createItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemControllerCreate(createItemDto: CreateItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemControllerCreate(createItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteItemDto} deleteItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemControllerDelete(deleteItemDto: DeleteItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemControllerDelete(deleteItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditItemDto} editItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemControllerEdit(editItemDto: EditItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemControllerEdit(editItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemApi - factory interface
 * @export
 */
export const ItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemApiFp(configuration)
    return {
        /**
         * 
         * @param {BulkItemsDto} bulkItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerBulk(bulkItemsDto: BulkItemsDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.itemControllerBulk(bulkItemsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateItemDto} createItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerCreate(createItemDto: CreateItemDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.itemControllerCreate(createItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteItemDto} deleteItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerDelete(deleteItemDto: DeleteItemDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.itemControllerDelete(deleteItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditItemDto} editItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerEdit(editItemDto: EditItemDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.itemControllerEdit(editItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<ItemControllerGetDataAsList200Response> {
            return localVarFp.itemControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemApi - object-oriented interface
 * @export
 * @class ItemApi
 * @extends {BaseAPI}
 */
export class ItemApi extends BaseAPI {
    /**
     * 
     * @param {BulkItemsDto} bulkItemsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerBulk(bulkItemsDto: BulkItemsDto, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemControllerBulk(bulkItemsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateItemDto} createItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerCreate(createItemDto: CreateItemDto, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemControllerCreate(createItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteItemDto} deleteItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerDelete(deleteItemDto: DeleteItemDto, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemControllerDelete(deleteItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditItemDto} editItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerEdit(editItemDto: EditItemDto, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemControllerEdit(editItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManualInventoryApi - axios parameter creator
 * @export
 */
export const ManualInventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateManualInventoryDto} createManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualInventoryControllerCreate: async (createManualInventoryDto: CreateManualInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManualInventoryDto' is not null or undefined
            assertParamExists('manualInventoryControllerCreate', 'createManualInventoryDto', createManualInventoryDto)
            const localVarPath = `/api/v1/manual-inventory/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManualInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteManualInventoryDto} deleteManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualInventoryControllerDelete: async (deleteManualInventoryDto: DeleteManualInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteManualInventoryDto' is not null or undefined
            assertParamExists('manualInventoryControllerDelete', 'deleteManualInventoryDto', deleteManualInventoryDto)
            const localVarPath = `/api/v1/manual-inventory/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteManualInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditManualInventoryDto} editManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualInventoryControllerEdit: async (editManualInventoryDto: EditManualInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editManualInventoryDto' is not null or undefined
            assertParamExists('manualInventoryControllerEdit', 'editManualInventoryDto', editManualInventoryDto)
            const localVarPath = `/api/v1/manual-inventory/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editManualInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualInventoryControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/manual-inventory/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManualInventoryApi - functional programming interface
 * @export
 */
export const ManualInventoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManualInventoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateManualInventoryDto} createManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualInventoryControllerCreate(createManualInventoryDto: CreateManualInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualInventoryControllerCreate(createManualInventoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteManualInventoryDto} deleteManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualInventoryControllerDelete(deleteManualInventoryDto: DeleteManualInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualInventoryControllerDelete(deleteManualInventoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditManualInventoryDto} editManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualInventoryControllerEdit(editManualInventoryDto: EditManualInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualInventoryControllerEdit(editManualInventoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualInventoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualInventoryControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualInventoryControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManualInventoryApi - factory interface
 * @export
 */
export const ManualInventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManualInventoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateManualInventoryDto} createManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualInventoryControllerCreate(createManualInventoryDto: CreateManualInventoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.manualInventoryControllerCreate(createManualInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteManualInventoryDto} deleteManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualInventoryControllerDelete(deleteManualInventoryDto: DeleteManualInventoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.manualInventoryControllerDelete(deleteManualInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditManualInventoryDto} editManualInventoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualInventoryControllerEdit(editManualInventoryDto: EditManualInventoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.manualInventoryControllerEdit(editManualInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualInventoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<ManualInventoryControllerGetDataAsList200Response> {
            return localVarFp.manualInventoryControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManualInventoryApi - object-oriented interface
 * @export
 * @class ManualInventoryApi
 * @extends {BaseAPI}
 */
export class ManualInventoryApi extends BaseAPI {
    /**
     * 
     * @param {CreateManualInventoryDto} createManualInventoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualInventoryApi
     */
    public manualInventoryControllerCreate(createManualInventoryDto: CreateManualInventoryDto, options?: AxiosRequestConfig) {
        return ManualInventoryApiFp(this.configuration).manualInventoryControllerCreate(createManualInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteManualInventoryDto} deleteManualInventoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualInventoryApi
     */
    public manualInventoryControllerDelete(deleteManualInventoryDto: DeleteManualInventoryDto, options?: AxiosRequestConfig) {
        return ManualInventoryApiFp(this.configuration).manualInventoryControllerDelete(deleteManualInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditManualInventoryDto} editManualInventoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualInventoryApi
     */
    public manualInventoryControllerEdit(editManualInventoryDto: EditManualInventoryDto, options?: AxiosRequestConfig) {
        return ManualInventoryApiFp(this.configuration).manualInventoryControllerEdit(editManualInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualInventoryApi
     */
    public manualInventoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return ManualInventoryApiFp(this.configuration).manualInventoryControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteNotificationDto} deleteNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerDelete: async (deleteNotificationDto: DeleteNotificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteNotificationDto' is not null or undefined
            assertParamExists('notificationControllerDelete', 'deleteNotificationDto', deleteNotificationDto)
            const localVarPath = `/api/v1/notification/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteNotificationDto} deleteNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerDelete(deleteNotificationDto: DeleteNotificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerDelete(deleteNotificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteNotificationDto} deleteNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerDelete(deleteNotificationDto: DeleteNotificationDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.notificationControllerDelete(deleteNotificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<NotificationControllerGetDataAsList200Response> {
            return localVarFp.notificationControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {DeleteNotificationDto} deleteNotificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationControllerDelete(deleteNotificationDto: DeleteNotificationDto, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationControllerDelete(deleteNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthControllerChangePassword: async (changePasswordDto: ChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordDto' is not null or undefined
            assertParamExists('oauthControllerChangePassword', 'changePasswordDto', changePasswordDto)
            const localVarPath = `/api/v1/oauth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthControllerForgotPassword: async (forgotPasswordDto: ForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordDto' is not null or undefined
            assertParamExists('oauthControllerForgotPassword', 'forgotPasswordDto', forgotPasswordDto)
            const localVarPath = `/api/v1/oauth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginPersonDto} loginPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthControllerLogin: async (loginPersonDto: LoginPersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginPersonDto' is not null or undefined
            assertParamExists('oauthControllerLogin', 'loginPersonDto', loginPersonDto)
            const localVarPath = `/api/v1/oauth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginPersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthControllerChangePassword(changePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthControllerForgotPassword(forgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginPersonDto} loginPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthControllerLogin(loginPersonDto: LoginPersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthControllerLogin(loginPersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OauthApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.oauthControllerChangePassword(changePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.oauthControllerForgotPassword(forgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginPersonDto} loginPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthControllerLogin(loginPersonDto: LoginPersonDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.oauthControllerLogin(loginPersonDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
    /**
     * 
     * @param {ChangePasswordDto} changePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthControllerChangePassword(changePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordDto} forgotPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthControllerForgotPassword(forgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginPersonDto} loginPersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthControllerLogin(loginPersonDto: LoginPersonDto, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthControllerLogin(loginPersonDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ActivatePersonDto} activatePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerActivateAccount: async (activatePersonDto: ActivatePersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activatePersonDto' is not null or undefined
            assertParamExists('personControllerActivateAccount', 'activatePersonDto', activatePersonDto)
            const localVarPath = `/api/v1/person/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activatePersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddPersonDto} addPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerAddPerson: async (addPersonDto: AddPersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addPersonDto' is not null or undefined
            assertParamExists('personControllerAddPerson', 'addPersonDto', addPersonDto)
            const localVarPath = `/api/v1/person/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerBlock: async (deletePersonDto: DeletePersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePersonDto' is not null or undefined
            assertParamExists('personControllerBlock', 'deletePersonDto', deletePersonDto)
            const localVarPath = `/api/v1/person/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminChangePasswordDto} adminChangePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerChangePassword: async (adminChangePasswordDto: AdminChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminChangePasswordDto' is not null or undefined
            assertParamExists('personControllerChangePassword', 'adminChangePasswordDto', adminChangePasswordDto)
            const localVarPath = `/api/v1/person/admin-change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminChangePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerDelete: async (deletePersonDto: DeletePersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePersonDto' is not null or undefined
            assertParamExists('personControllerDelete', 'deletePersonDto', deletePersonDto)
            const localVarPath = `/api/v1/person/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/person/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerUnblock: async (deletePersonDto: DeletePersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePersonDto' is not null or undefined
            assertParamExists('personControllerUnblock', 'deletePersonDto', deletePersonDto)
            const localVarPath = `/api/v1/person/unblock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePersonDto} updatePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerUpdatePerson: async (updatePersonDto: UpdatePersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePersonDto' is not null or undefined
            assertParamExists('personControllerUpdatePerson', 'updatePersonDto', updatePersonDto)
            const localVarPath = `/api/v1/person/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ActivatePersonDto} activatePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerActivateAccount(activatePersonDto: ActivatePersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerActivateAccount(activatePersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddPersonDto} addPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerAddPerson(addPersonDto: AddPersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerAddPerson(addPersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerBlock(deletePersonDto: DeletePersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerBlock(deletePersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminChangePasswordDto} adminChangePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerChangePassword(adminChangePasswordDto: AdminChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerChangePassword(adminChangePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerDelete(deletePersonDto: DeletePersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerDelete(deletePersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerUnblock(deletePersonDto: DeletePersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerUnblock(deletePersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePersonDto} updatePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerUpdatePerson(updatePersonDto: UpdatePersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerUpdatePerson(updatePersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonApiFp(configuration)
    return {
        /**
         * 
         * @param {ActivatePersonDto} activatePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerActivateAccount(activatePersonDto: ActivatePersonDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerActivateAccount(activatePersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddPersonDto} addPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerAddPerson(addPersonDto: AddPersonDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerAddPerson(addPersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerBlock(deletePersonDto: DeletePersonDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerBlock(deletePersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminChangePasswordDto} adminChangePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerChangePassword(adminChangePasswordDto: AdminChangePasswordDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerChangePassword(adminChangePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerDelete(deletePersonDto: DeletePersonDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerDelete(deletePersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<PersonControllerGetDataAsList200Response> {
            return localVarFp.personControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePersonDto} deletePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerUnblock(deletePersonDto: DeletePersonDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerUnblock(deletePersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePersonDto} updatePersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerUpdatePerson(updatePersonDto: UpdatePersonDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.personControllerUpdatePerson(updatePersonDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
    /**
     * 
     * @param {ActivatePersonDto} activatePersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerActivateAccount(activatePersonDto: ActivatePersonDto, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerActivateAccount(activatePersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddPersonDto} addPersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerAddPerson(addPersonDto: AddPersonDto, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerAddPerson(addPersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePersonDto} deletePersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerBlock(deletePersonDto: DeletePersonDto, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerBlock(deletePersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminChangePasswordDto} adminChangePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerChangePassword(adminChangePasswordDto: AdminChangePasswordDto, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerChangePassword(adminChangePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePersonDto} deletePersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerDelete(deletePersonDto: DeletePersonDto, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerDelete(deletePersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePersonDto} deletePersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerUnblock(deletePersonDto: DeletePersonDto, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerUnblock(deletePersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePersonDto} updatePersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerUpdatePerson(updatePersonDto: UpdatePersonDto, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerUpdatePerson(updatePersonDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseOrderApi - axios parameter creator
 * @export
 */
export const PurchaseOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePurchaseOrderDto} createPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerCreate: async (createPurchaseOrderDto: CreatePurchaseOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPurchaseOrderDto' is not null or undefined
            assertParamExists('purchaseOrderControllerCreate', 'createPurchaseOrderDto', createPurchaseOrderDto)
            const localVarPath = `/api/v1/purchase-order/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPurchaseOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePurchaseOrderDto} deletePurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerDelete: async (deletePurchaseOrderDto: DeletePurchaseOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePurchaseOrderDto' is not null or undefined
            assertParamExists('purchaseOrderControllerDelete', 'deletePurchaseOrderDto', deletePurchaseOrderDto)
            const localVarPath = `/api/v1/purchase-order/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePurchaseOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPurchaseOrderDto} editPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerEdit: async (editPurchaseOrderDto: EditPurchaseOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPurchaseOrderDto' is not null or undefined
            assertParamExists('purchaseOrderControllerEdit', 'editPurchaseOrderDto', editPurchaseOrderDto)
            const localVarPath = `/api/v1/purchase-order/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPurchaseOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/purchase-order/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessPurchaseOrderDto} processPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerProcess: async (processPurchaseOrderDto: ProcessPurchaseOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processPurchaseOrderDto' is not null or undefined
            assertParamExists('purchaseOrderControllerProcess', 'processPurchaseOrderDto', processPurchaseOrderDto)
            const localVarPath = `/api/v1/purchase-order/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processPurchaseOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReceivePurchaseOrderDto} receivePurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerReceive: async (receivePurchaseOrderDto: ReceivePurchaseOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receivePurchaseOrderDto' is not null or undefined
            assertParamExists('purchaseOrderControllerReceive', 'receivePurchaseOrderDto', receivePurchaseOrderDto)
            const localVarPath = `/api/v1/purchase-order/receive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receivePurchaseOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseOrderApi - functional programming interface
 * @export
 */
export const PurchaseOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePurchaseOrderDto} createPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseOrderControllerCreate(createPurchaseOrderDto: CreatePurchaseOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseOrderControllerCreate(createPurchaseOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePurchaseOrderDto} deletePurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseOrderControllerDelete(deletePurchaseOrderDto: DeletePurchaseOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseOrderControllerDelete(deletePurchaseOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPurchaseOrderDto} editPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseOrderControllerEdit(editPurchaseOrderDto: EditPurchaseOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseOrderControllerEdit(editPurchaseOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseOrderControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseOrderControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessPurchaseOrderDto} processPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseOrderControllerProcess(processPurchaseOrderDto: ProcessPurchaseOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseOrderControllerProcess(processPurchaseOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReceivePurchaseOrderDto} receivePurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseOrderControllerReceive(receivePurchaseOrderDto: ReceivePurchaseOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseOrderControllerReceive(receivePurchaseOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseOrderApi - factory interface
 * @export
 */
export const PurchaseOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseOrderApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePurchaseOrderDto} createPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerCreate(createPurchaseOrderDto: CreatePurchaseOrderDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseOrderControllerCreate(createPurchaseOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePurchaseOrderDto} deletePurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerDelete(deletePurchaseOrderDto: DeletePurchaseOrderDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseOrderControllerDelete(deletePurchaseOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPurchaseOrderDto} editPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerEdit(editPurchaseOrderDto: EditPurchaseOrderDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseOrderControllerEdit(editPurchaseOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PurchaseOrderControllerGetDataAsList200Response> {
            return localVarFp.purchaseOrderControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessPurchaseOrderDto} processPurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerProcess(processPurchaseOrderDto: ProcessPurchaseOrderDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseOrderControllerProcess(processPurchaseOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReceivePurchaseOrderDto} receivePurchaseOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderControllerReceive(receivePurchaseOrderDto: ReceivePurchaseOrderDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseOrderControllerReceive(receivePurchaseOrderDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseOrderApi - object-oriented interface
 * @export
 * @class PurchaseOrderApi
 * @extends {BaseAPI}
 */
export class PurchaseOrderApi extends BaseAPI {
    /**
     * 
     * @param {CreatePurchaseOrderDto} createPurchaseOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderControllerCreate(createPurchaseOrderDto: CreatePurchaseOrderDto, options?: AxiosRequestConfig) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderControllerCreate(createPurchaseOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePurchaseOrderDto} deletePurchaseOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderControllerDelete(deletePurchaseOrderDto: DeletePurchaseOrderDto, options?: AxiosRequestConfig) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderControllerDelete(deletePurchaseOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPurchaseOrderDto} editPurchaseOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderControllerEdit(editPurchaseOrderDto: EditPurchaseOrderDto, options?: AxiosRequestConfig) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderControllerEdit(editPurchaseOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessPurchaseOrderDto} processPurchaseOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderControllerProcess(processPurchaseOrderDto: ProcessPurchaseOrderDto, options?: AxiosRequestConfig) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderControllerProcess(processPurchaseOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReceivePurchaseOrderDto} receivePurchaseOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderControllerReceive(receivePurchaseOrderDto: ReceivePurchaseOrderDto, options?: AxiosRequestConfig) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderControllerReceive(receivePurchaseOrderDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseOrderPurchaseRequestApi - axios parameter creator
 * @export
 */
export const PurchaseOrderPurchaseRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePoPrDto} createPoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poPrControllerCreate: async (createPoPrDto: CreatePoPrDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPoPrDto' is not null or undefined
            assertParamExists('poPrControllerCreate', 'createPoPrDto', createPoPrDto)
            const localVarPath = `/api/v1/po-pr/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPoPrDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePoPrDto} deletePoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poPrControllerDelete: async (deletePoPrDto: DeletePoPrDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePoPrDto' is not null or undefined
            assertParamExists('poPrControllerDelete', 'deletePoPrDto', deletePoPrDto)
            const localVarPath = `/api/v1/po-pr/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePoPrDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPoPrDto} editPoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poPrControllerEdit: async (editPoPrDto: EditPoPrDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPoPrDto' is not null or undefined
            assertParamExists('poPrControllerEdit', 'editPoPrDto', editPoPrDto)
            const localVarPath = `/api/v1/po-pr/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPoPrDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poPrControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/po-pr/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseOrderPurchaseRequestApi - functional programming interface
 * @export
 */
export const PurchaseOrderPurchaseRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseOrderPurchaseRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePoPrDto} createPoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async poPrControllerCreate(createPoPrDto: CreatePoPrDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.poPrControllerCreate(createPoPrDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePoPrDto} deletePoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async poPrControllerDelete(deletePoPrDto: DeletePoPrDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.poPrControllerDelete(deletePoPrDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPoPrDto} editPoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async poPrControllerEdit(editPoPrDto: EditPoPrDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.poPrControllerEdit(editPoPrDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async poPrControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoPrControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.poPrControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseOrderPurchaseRequestApi - factory interface
 * @export
 */
export const PurchaseOrderPurchaseRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseOrderPurchaseRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePoPrDto} createPoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poPrControllerCreate(createPoPrDto: CreatePoPrDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.poPrControllerCreate(createPoPrDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePoPrDto} deletePoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poPrControllerDelete(deletePoPrDto: DeletePoPrDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.poPrControllerDelete(deletePoPrDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPoPrDto} editPoPrDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poPrControllerEdit(editPoPrDto: EditPoPrDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.poPrControllerEdit(editPoPrDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poPrControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<PoPrControllerGetDataAsList200Response> {
            return localVarFp.poPrControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseOrderPurchaseRequestApi - object-oriented interface
 * @export
 * @class PurchaseOrderPurchaseRequestApi
 * @extends {BaseAPI}
 */
export class PurchaseOrderPurchaseRequestApi extends BaseAPI {
    /**
     * 
     * @param {CreatePoPrDto} createPoPrDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderPurchaseRequestApi
     */
    public poPrControllerCreate(createPoPrDto: CreatePoPrDto, options?: AxiosRequestConfig) {
        return PurchaseOrderPurchaseRequestApiFp(this.configuration).poPrControllerCreate(createPoPrDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePoPrDto} deletePoPrDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderPurchaseRequestApi
     */
    public poPrControllerDelete(deletePoPrDto: DeletePoPrDto, options?: AxiosRequestConfig) {
        return PurchaseOrderPurchaseRequestApiFp(this.configuration).poPrControllerDelete(deletePoPrDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPoPrDto} editPoPrDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderPurchaseRequestApi
     */
    public poPrControllerEdit(editPoPrDto: EditPoPrDto, options?: AxiosRequestConfig) {
        return PurchaseOrderPurchaseRequestApiFp(this.configuration).poPrControllerEdit(editPoPrDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderPurchaseRequestApi
     */
    public poPrControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return PurchaseOrderPurchaseRequestApiFp(this.configuration).poPrControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseRequestApi - axios parameter creator
 * @export
 */
export const PurchaseRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePurchaseRequestDto} createPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerCreate: async (createPurchaseRequestDto: CreatePurchaseRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPurchaseRequestDto' is not null or undefined
            assertParamExists('purchaseRequestControllerCreate', 'createPurchaseRequestDto', createPurchaseRequestDto)
            const localVarPath = `/api/v1/purchase-request/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPurchaseRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePurchaseRequestDto} deletePurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerDelete: async (deletePurchaseRequestDto: DeletePurchaseRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePurchaseRequestDto' is not null or undefined
            assertParamExists('purchaseRequestControllerDelete', 'deletePurchaseRequestDto', deletePurchaseRequestDto)
            const localVarPath = `/api/v1/purchase-request/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePurchaseRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPurchaseRequestDto} editPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerEdit: async (editPurchaseRequestDto: EditPurchaseRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPurchaseRequestDto' is not null or undefined
            assertParamExists('purchaseRequestControllerEdit', 'editPurchaseRequestDto', editPurchaseRequestDto)
            const localVarPath = `/api/v1/purchase-request/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPurchaseRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/purchase-request/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (dateFilter !== undefined) {
                localVarQueryParameter['date_filter'] = dateFilter;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerGetUnassisgnedPrAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/purchase-request/get-unassigned-pr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessPurchaseRequestDto} processPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerProcess: async (processPurchaseRequestDto: ProcessPurchaseRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processPurchaseRequestDto' is not null or undefined
            assertParamExists('purchaseRequestControllerProcess', 'processPurchaseRequestDto', processPurchaseRequestDto)
            const localVarPath = `/api/v1/purchase-request/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processPurchaseRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReceivePurchaseRequestDto} receivePurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerReceive: async (receivePurchaseRequestDto: ReceivePurchaseRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receivePurchaseRequestDto' is not null or undefined
            assertParamExists('purchaseRequestControllerReceive', 'receivePurchaseRequestDto', receivePurchaseRequestDto)
            const localVarPath = `/api/v1/purchase-request/receive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receivePurchaseRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseRequestApi - functional programming interface
 * @export
 */
export const PurchaseRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePurchaseRequestDto} createPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseRequestControllerCreate(createPurchaseRequestDto: CreatePurchaseRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseRequestControllerCreate(createPurchaseRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePurchaseRequestDto} deletePurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseRequestControllerDelete(deletePurchaseRequestDto: DeletePurchaseRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseRequestControllerDelete(deletePurchaseRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPurchaseRequestDto} editPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseRequestControllerEdit(editPurchaseRequestDto: EditPurchaseRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseRequestControllerEdit(editPurchaseRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseRequestControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseRequestControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseRequestControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseRequestControllerGetUnassisgnedPrAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseRequestControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseRequestControllerGetUnassisgnedPrAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessPurchaseRequestDto} processPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseRequestControllerProcess(processPurchaseRequestDto: ProcessPurchaseRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseRequestControllerProcess(processPurchaseRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReceivePurchaseRequestDto} receivePurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseRequestControllerReceive(receivePurchaseRequestDto: ReceivePurchaseRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseRequestControllerReceive(receivePurchaseRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseRequestApi - factory interface
 * @export
 */
export const PurchaseRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePurchaseRequestDto} createPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerCreate(createPurchaseRequestDto: CreatePurchaseRequestDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseRequestControllerCreate(createPurchaseRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePurchaseRequestDto} deletePurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerDelete(deletePurchaseRequestDto: DeletePurchaseRequestDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseRequestControllerDelete(deletePurchaseRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPurchaseRequestDto} editPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerEdit(editPurchaseRequestDto: EditPurchaseRequestDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseRequestControllerEdit(editPurchaseRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
         * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PurchaseRequestControllerGetDataAsList200Response> {
            return localVarFp.purchaseRequestControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerGetUnassisgnedPrAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<PurchaseRequestControllerGetDataAsList200Response> {
            return localVarFp.purchaseRequestControllerGetUnassisgnedPrAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessPurchaseRequestDto} processPurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerProcess(processPurchaseRequestDto: ProcessPurchaseRequestDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseRequestControllerProcess(processPurchaseRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReceivePurchaseRequestDto} receivePurchaseRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequestControllerReceive(receivePurchaseRequestDto: ReceivePurchaseRequestDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.purchaseRequestControllerReceive(receivePurchaseRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseRequestApi - object-oriented interface
 * @export
 * @class PurchaseRequestApi
 * @extends {BaseAPI}
 */
export class PurchaseRequestApi extends BaseAPI {
    /**
     * 
     * @param {CreatePurchaseRequestDto} createPurchaseRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestApi
     */
    public purchaseRequestControllerCreate(createPurchaseRequestDto: CreatePurchaseRequestDto, options?: AxiosRequestConfig) {
        return PurchaseRequestApiFp(this.configuration).purchaseRequestControllerCreate(createPurchaseRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePurchaseRequestDto} deletePurchaseRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestApi
     */
    public purchaseRequestControllerDelete(deletePurchaseRequestDto: DeletePurchaseRequestDto, options?: AxiosRequestConfig) {
        return PurchaseRequestApiFp(this.configuration).purchaseRequestControllerDelete(deletePurchaseRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPurchaseRequestDto} editPurchaseRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestApi
     */
    public purchaseRequestControllerEdit(editPurchaseRequestDto: EditPurchaseRequestDto, options?: AxiosRequestConfig) {
        return PurchaseRequestApiFp(this.configuration).purchaseRequestControllerEdit(editPurchaseRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestApi
     */
    public purchaseRequestControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, dateFilter?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return PurchaseRequestApiFp(this.configuration).purchaseRequestControllerGetDataAsList(search, limit, offset, order, filter, dateFilter, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestApi
     */
    public purchaseRequestControllerGetUnassisgnedPrAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return PurchaseRequestApiFp(this.configuration).purchaseRequestControllerGetUnassisgnedPrAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessPurchaseRequestDto} processPurchaseRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestApi
     */
    public purchaseRequestControllerProcess(processPurchaseRequestDto: ProcessPurchaseRequestDto, options?: AxiosRequestConfig) {
        return PurchaseRequestApiFp(this.configuration).purchaseRequestControllerProcess(processPurchaseRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReceivePurchaseRequestDto} receivePurchaseRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestApi
     */
    public purchaseRequestControllerReceive(receivePurchaseRequestDto: ReceivePurchaseRequestDto, options?: AxiosRequestConfig) {
        return PurchaseRequestApiFp(this.configuration).purchaseRequestControllerReceive(receivePurchaseRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseRequestItemApi - axios parameter creator
 * @export
 */
export const PurchaseRequestItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePrItemDto} createPrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemControllerCreate: async (createPrItemDto: CreatePrItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPrItemDto' is not null or undefined
            assertParamExists('prItemControllerCreate', 'createPrItemDto', createPrItemDto)
            const localVarPath = `/api/v1/pr-item/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPrItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePrItemDto} deletePrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemControllerDelete: async (deletePrItemDto: DeletePrItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePrItemDto' is not null or undefined
            assertParamExists('prItemControllerDelete', 'deletePrItemDto', deletePrItemDto)
            const localVarPath = `/api/v1/pr-item/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePrItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPrItemDto} editPrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemControllerEdit: async (editPrItemDto: EditPrItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPrItemDto' is not null or undefined
            assertParamExists('prItemControllerEdit', 'editPrItemDto', editPrItemDto)
            const localVarPath = `/api/v1/pr-item/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPrItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pr-item/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseRequestItemApi - functional programming interface
 * @export
 */
export const PurchaseRequestItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseRequestItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePrItemDto} createPrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemControllerCreate(createPrItemDto: CreatePrItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemControllerCreate(createPrItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePrItemDto} deletePrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemControllerDelete(deletePrItemDto: DeletePrItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemControllerDelete(deletePrItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPrItemDto} editPrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemControllerEdit(editPrItemDto: EditPrItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemControllerEdit(editPrItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrItemControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseRequestItemApi - factory interface
 * @export
 */
export const PurchaseRequestItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseRequestItemApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePrItemDto} createPrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemControllerCreate(createPrItemDto: CreatePrItemDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.prItemControllerCreate(createPrItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePrItemDto} deletePrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemControllerDelete(deletePrItemDto: DeletePrItemDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.prItemControllerDelete(deletePrItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPrItemDto} editPrItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemControllerEdit(editPrItemDto: EditPrItemDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.prItemControllerEdit(editPrItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<PrItemControllerGetDataAsList200Response> {
            return localVarFp.prItemControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseRequestItemApi - object-oriented interface
 * @export
 * @class PurchaseRequestItemApi
 * @extends {BaseAPI}
 */
export class PurchaseRequestItemApi extends BaseAPI {
    /**
     * 
     * @param {CreatePrItemDto} createPrItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemApi
     */
    public prItemControllerCreate(createPrItemDto: CreatePrItemDto, options?: AxiosRequestConfig) {
        return PurchaseRequestItemApiFp(this.configuration).prItemControllerCreate(createPrItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePrItemDto} deletePrItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemApi
     */
    public prItemControllerDelete(deletePrItemDto: DeletePrItemDto, options?: AxiosRequestConfig) {
        return PurchaseRequestItemApiFp(this.configuration).prItemControllerDelete(deletePrItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPrItemDto} editPrItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemApi
     */
    public prItemControllerEdit(editPrItemDto: EditPrItemDto, options?: AxiosRequestConfig) {
        return PurchaseRequestItemApiFp(this.configuration).prItemControllerEdit(editPrItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemApi
     */
    public prItemControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return PurchaseRequestItemApiFp(this.configuration).prItemControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseRequestItemDeliveryApi - axios parameter creator
 * @export
 */
export const PurchaseRequestItemDeliveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePIDDto} createPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerCreate: async (createPIDDto: CreatePIDDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPIDDto' is not null or undefined
            assertParamExists('prItemDeliveryControllerCreate', 'createPIDDto', createPIDDto)
            const localVarPath = `/api/v1/pr-item-delivery/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPIDDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePIDDto} deletePIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerDelete: async (deletePIDDto: DeletePIDDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePIDDto' is not null or undefined
            assertParamExists('prItemDeliveryControllerDelete', 'deletePIDDto', deletePIDDto)
            const localVarPath = `/api/v1/pr-item-delivery/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePIDDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPIDDto} editPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerEdit: async (editPIDDto: EditPIDDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPIDDto' is not null or undefined
            assertParamExists('prItemDeliveryControllerEdit', 'editPIDDto', editPIDDto)
            const localVarPath = `/api/v1/pr-item-delivery/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPIDDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pr-item-delivery/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MonitorPIDDto} monitorPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerMonitor: async (monitorPIDDto: MonitorPIDDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorPIDDto' is not null or undefined
            assertParamExists('prItemDeliveryControllerMonitor', 'monitorPIDDto', monitorPIDDto)
            const localVarPath = `/api/v1/pr-item-delivery/send-to-monitoring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorPIDDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MonitorDto} monitorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerMonitorV2: async (monitorDto: MonitorDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorDto' is not null or undefined
            assertParamExists('prItemDeliveryControllerMonitorV2', 'monitorDto', monitorDto)
            const localVarPath = `/api/v1/pr-item-delivery/monitor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseRequestItemDeliveryApi - functional programming interface
 * @export
 */
export const PurchaseRequestItemDeliveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseRequestItemDeliveryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePIDDto} createPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemDeliveryControllerCreate(createPIDDto: CreatePIDDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemDeliveryControllerCreate(createPIDDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePIDDto} deletePIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemDeliveryControllerDelete(deletePIDDto: DeletePIDDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemDeliveryControllerDelete(deletePIDDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPIDDto} editPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemDeliveryControllerEdit(editPIDDto: EditPIDDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemDeliveryControllerEdit(editPIDDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemDeliveryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrItemDeliveryControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemDeliveryControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MonitorPIDDto} monitorPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemDeliveryControllerMonitor(monitorPIDDto: MonitorPIDDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemDeliveryControllerMonitor(monitorPIDDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MonitorDto} monitorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prItemDeliveryControllerMonitorV2(monitorDto: MonitorDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prItemDeliveryControllerMonitorV2(monitorDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseRequestItemDeliveryApi - factory interface
 * @export
 */
export const PurchaseRequestItemDeliveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseRequestItemDeliveryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePIDDto} createPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerCreate(createPIDDto: CreatePIDDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.prItemDeliveryControllerCreate(createPIDDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePIDDto} deletePIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerDelete(deletePIDDto: DeletePIDDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.prItemDeliveryControllerDelete(deletePIDDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPIDDto} editPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerEdit(editPIDDto: EditPIDDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.prItemDeliveryControllerEdit(editPIDDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<PrItemDeliveryControllerGetDataAsList200Response> {
            return localVarFp.prItemDeliveryControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MonitorPIDDto} monitorPIDDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerMonitor(monitorPIDDto: MonitorPIDDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.prItemDeliveryControllerMonitor(monitorPIDDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MonitorDto} monitorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prItemDeliveryControllerMonitorV2(monitorDto: MonitorDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.prItemDeliveryControllerMonitorV2(monitorDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseRequestItemDeliveryApi - object-oriented interface
 * @export
 * @class PurchaseRequestItemDeliveryApi
 * @extends {BaseAPI}
 */
export class PurchaseRequestItemDeliveryApi extends BaseAPI {
    /**
     * 
     * @param {CreatePIDDto} createPIDDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemDeliveryApi
     */
    public prItemDeliveryControllerCreate(createPIDDto: CreatePIDDto, options?: AxiosRequestConfig) {
        return PurchaseRequestItemDeliveryApiFp(this.configuration).prItemDeliveryControllerCreate(createPIDDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePIDDto} deletePIDDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemDeliveryApi
     */
    public prItemDeliveryControllerDelete(deletePIDDto: DeletePIDDto, options?: AxiosRequestConfig) {
        return PurchaseRequestItemDeliveryApiFp(this.configuration).prItemDeliveryControllerDelete(deletePIDDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPIDDto} editPIDDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemDeliveryApi
     */
    public prItemDeliveryControllerEdit(editPIDDto: EditPIDDto, options?: AxiosRequestConfig) {
        return PurchaseRequestItemDeliveryApiFp(this.configuration).prItemDeliveryControllerEdit(editPIDDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemDeliveryApi
     */
    public prItemDeliveryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return PurchaseRequestItemDeliveryApiFp(this.configuration).prItemDeliveryControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MonitorPIDDto} monitorPIDDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemDeliveryApi
     */
    public prItemDeliveryControllerMonitor(monitorPIDDto: MonitorPIDDto, options?: AxiosRequestConfig) {
        return PurchaseRequestItemDeliveryApiFp(this.configuration).prItemDeliveryControllerMonitor(monitorPIDDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MonitorDto} monitorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequestItemDeliveryApi
     */
    public prItemDeliveryControllerMonitorV2(monitorDto: MonitorDto, options?: AxiosRequestConfig) {
        return PurchaseRequestItemDeliveryApiFp(this.configuration).prItemDeliveryControllerMonitorV2(monitorDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskControllerQueueEmail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/task/queue-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskControllerTestEmail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/task/test-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskControllerQueueEmail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskControllerQueueEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskControllerTestEmail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskControllerTestEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskControllerQueueEmail(options?: any): AxiosPromise<void> {
            return localVarFp.taskControllerQueueEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskControllerTestEmail(options?: any): AxiosPromise<void> {
            return localVarFp.taskControllerTestEmail(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskControllerQueueEmail(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskControllerQueueEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskControllerTestEmail(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskControllerTestEmail(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionHistoryApi - axios parameter creator
 * @export
 */
export const TransactionHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteTransactionHistoryDto} deleteTransactionHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionHistoryControllerDelete: async (deleteTransactionHistoryDto: DeleteTransactionHistoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteTransactionHistoryDto' is not null or undefined
            assertParamExists('transactionHistoryControllerDelete', 'deleteTransactionHistoryDto', deleteTransactionHistoryDto)
            const localVarPath = `/api/v1/transaction-history/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTransactionHistoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionHistoryControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/transaction-history/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionHistoryApi - functional programming interface
 * @export
 */
export const TransactionHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteTransactionHistoryDto} deleteTransactionHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionHistoryControllerDelete(deleteTransactionHistoryDto: DeleteTransactionHistoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionHistoryControllerDelete(deleteTransactionHistoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionHistoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionHistoryControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionHistoryControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionHistoryApi - factory interface
 * @export
 */
export const TransactionHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteTransactionHistoryDto} deleteTransactionHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionHistoryControllerDelete(deleteTransactionHistoryDto: DeleteTransactionHistoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.transactionHistoryControllerDelete(deleteTransactionHistoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionHistoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<TransactionHistoryControllerGetDataAsList200Response> {
            return localVarFp.transactionHistoryControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionHistoryApi - object-oriented interface
 * @export
 * @class TransactionHistoryApi
 * @extends {BaseAPI}
 */
export class TransactionHistoryApi extends BaseAPI {
    /**
     * 
     * @param {DeleteTransactionHistoryDto} deleteTransactionHistoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public transactionHistoryControllerDelete(deleteTransactionHistoryDto: DeleteTransactionHistoryDto, options?: AxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).transactionHistoryControllerDelete(deleteTransactionHistoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionHistoryApi
     */
    public transactionHistoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return TransactionHistoryApiFp(this.configuration).transactionHistoryControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
export const UtilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} province 
         * @param {string} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerBarangay: async (region: string, province: string, city: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('utilitiesControllerBarangay', 'region', region)
            // verify required parameter 'province' is not null or undefined
            assertParamExists('utilitiesControllerBarangay', 'province', province)
            // verify required parameter 'city' is not null or undefined
            assertParamExists('utilitiesControllerBarangay', 'city', city)
            const localVarPath = `/api/v1/utilities/barangay/{region}/{province}/{city}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"province"}}`, encodeURIComponent(String(province)))
                .replace(`{${"city"}}`, encodeURIComponent(String(city)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} province 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerCities: async (region: string, province: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('utilitiesControllerCities', 'region', region)
            // verify required parameter 'province' is not null or undefined
            assertParamExists('utilitiesControllerCities', 'province', province)
            const localVarPath = `/api/v1/utilities/cities/{region}/{province}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"province"}}`, encodeURIComponent(String(province)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerCivilStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utilities/civil-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerGender: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utilities/gender`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerGetUtilsRoleDataAsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utilities/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerHouseholdRole: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utilities/household-role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerProvinces: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('utilitiesControllerProvinces', 'region', region)
            const localVarPath = `/api/v1/utilities/provinces/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerPulseCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utilities/pulse-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerPuroks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utilities/puroks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerRegions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utilities/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitiesApi - functional programming interface
 * @export
 */
export const UtilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} province 
         * @param {string} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerBarangay(region: string, province: string, city: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerBarangay(region, province, city, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} province 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerCities(region: string, province: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerCities(region, province, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerCivilStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerCivilStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerGender(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerGender(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerGetUtilsRoleDataAsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerGetUtilsRoleDataAsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerHouseholdRole(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerHouseholdRole(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerProvinces(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerProvinces(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerPulseCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerPulseCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerPuroks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerPuroks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesControllerRegions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesControllerRegions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilitiesApi - factory interface
 * @export
 */
export const UtilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} region 
         * @param {string} province 
         * @param {string} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerBarangay(region: string, province: string, city: string, options?: any): AxiosPromise<ListDataDto> {
            return localVarFp.utilitiesControllerBarangay(region, province, city, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} province 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerCities(region: string, province: string, options?: any): AxiosPromise<ListDataDto> {
            return localVarFp.utilitiesControllerCities(region, province, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerCivilStatus(options?: any): AxiosPromise<ListDataDto> {
            return localVarFp.utilitiesControllerCivilStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerGender(options?: any): AxiosPromise<ListDataDto> {
            return localVarFp.utilitiesControllerGender(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerGetUtilsRoleDataAsList(options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.utilitiesControllerGetUtilsRoleDataAsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerHouseholdRole(options?: any): AxiosPromise<ListDataDto> {
            return localVarFp.utilitiesControllerHouseholdRole(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerProvinces(region: string, options?: any): AxiosPromise<ListDataDto> {
            return localVarFp.utilitiesControllerProvinces(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerPulseCheck(options?: any): AxiosPromise<void> {
            return localVarFp.utilitiesControllerPulseCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerPuroks(options?: any): AxiosPromise<ListDataDto> {
            return localVarFp.utilitiesControllerPuroks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesControllerRegions(options?: any): AxiosPromise<ListDataDto> {
            return localVarFp.utilitiesControllerRegions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
export class UtilitiesApi extends BaseAPI {
    /**
     * 
     * @param {string} region 
     * @param {string} province 
     * @param {string} city 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerBarangay(region: string, province: string, city: string, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerBarangay(region, province, city, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} province 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerCities(region: string, province: string, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerCities(region, province, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerCivilStatus(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerCivilStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerGender(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerGender(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerGetUtilsRoleDataAsList(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerGetUtilsRoleDataAsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerHouseholdRole(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerHouseholdRole(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerProvinces(region: string, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerProvinces(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerPulseCheck(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerPulseCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerPuroks(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerPuroks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesControllerRegions(options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).utilitiesControllerRegions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsBrandApi - axios parameter creator
 * @export
 */
export const UtilsBrandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUtilsBrandDto} createUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsBrandControllerCreate: async (createUtilsBrandDto: CreateUtilsBrandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUtilsBrandDto' is not null or undefined
            assertParamExists('utilsBrandControllerCreate', 'createUtilsBrandDto', createUtilsBrandDto)
            const localVarPath = `/api/v1/utils-brand/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUtilsBrandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUtilsBrandDto} deleteUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsBrandControllerDelete: async (deleteUtilsBrandDto: DeleteUtilsBrandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUtilsBrandDto' is not null or undefined
            assertParamExists('utilsBrandControllerDelete', 'deleteUtilsBrandDto', deleteUtilsBrandDto)
            const localVarPath = `/api/v1/utils-brand/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUtilsBrandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUtilsBrandDto} editUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsBrandControllerEdit: async (editUtilsBrandDto: EditUtilsBrandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUtilsBrandDto' is not null or undefined
            assertParamExists('utilsBrandControllerEdit', 'editUtilsBrandDto', editUtilsBrandDto)
            const localVarPath = `/api/v1/utils-brand/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUtilsBrandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsBrandControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils-brand/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsBrandApi - functional programming interface
 * @export
 */
export const UtilsBrandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsBrandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsBrandDto} createUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsBrandControllerCreate(createUtilsBrandDto: CreateUtilsBrandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsBrandControllerCreate(createUtilsBrandDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUtilsBrandDto} deleteUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsBrandControllerDelete(deleteUtilsBrandDto: DeleteUtilsBrandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsBrandControllerDelete(deleteUtilsBrandDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUtilsBrandDto} editUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsBrandControllerEdit(editUtilsBrandDto: EditUtilsBrandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsBrandControllerEdit(editUtilsBrandDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsBrandControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsBrandControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsBrandApi - factory interface
 * @export
 */
export const UtilsBrandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsBrandApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsBrandDto} createUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsBrandControllerCreate(createUtilsBrandDto: CreateUtilsBrandDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsBrandControllerCreate(createUtilsBrandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUtilsBrandDto} deleteUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsBrandControllerDelete(deleteUtilsBrandDto: DeleteUtilsBrandDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsBrandControllerDelete(deleteUtilsBrandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUtilsBrandDto} editUtilsBrandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsBrandControllerEdit(editUtilsBrandDto: EditUtilsBrandDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsBrandControllerEdit(editUtilsBrandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsBrandControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.utilsBrandControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsBrandApi - object-oriented interface
 * @export
 * @class UtilsBrandApi
 * @extends {BaseAPI}
 */
export class UtilsBrandApi extends BaseAPI {
    /**
     * 
     * @param {CreateUtilsBrandDto} createUtilsBrandDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsBrandApi
     */
    public utilsBrandControllerCreate(createUtilsBrandDto: CreateUtilsBrandDto, options?: AxiosRequestConfig) {
        return UtilsBrandApiFp(this.configuration).utilsBrandControllerCreate(createUtilsBrandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUtilsBrandDto} deleteUtilsBrandDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsBrandApi
     */
    public utilsBrandControllerDelete(deleteUtilsBrandDto: DeleteUtilsBrandDto, options?: AxiosRequestConfig) {
        return UtilsBrandApiFp(this.configuration).utilsBrandControllerDelete(deleteUtilsBrandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUtilsBrandDto} editUtilsBrandDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsBrandApi
     */
    public utilsBrandControllerEdit(editUtilsBrandDto: EditUtilsBrandDto, options?: AxiosRequestConfig) {
        return UtilsBrandApiFp(this.configuration).utilsBrandControllerEdit(editUtilsBrandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsBrandApi
     */
    public utilsBrandControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return UtilsBrandApiFp(this.configuration).utilsBrandControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsCateogryApi - axios parameter creator
 * @export
 */
export const UtilsCateogryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUtilsCategoryDto} createUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsCategoryControllerCreate: async (createUtilsCategoryDto: CreateUtilsCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUtilsCategoryDto' is not null or undefined
            assertParamExists('utilsCategoryControllerCreate', 'createUtilsCategoryDto', createUtilsCategoryDto)
            const localVarPath = `/api/v1/utils-cateogry/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUtilsCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUtilsCategoryDto} deleteUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsCategoryControllerDelete: async (deleteUtilsCategoryDto: DeleteUtilsCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUtilsCategoryDto' is not null or undefined
            assertParamExists('utilsCategoryControllerDelete', 'deleteUtilsCategoryDto', deleteUtilsCategoryDto)
            const localVarPath = `/api/v1/utils-cateogry/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUtilsCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUtilsCategoryDto} editUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsCategoryControllerEdit: async (editUtilsCategoryDto: EditUtilsCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUtilsCategoryDto' is not null or undefined
            assertParamExists('utilsCategoryControllerEdit', 'editUtilsCategoryDto', editUtilsCategoryDto)
            const localVarPath = `/api/v1/utils-cateogry/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUtilsCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsCategoryControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils-cateogry/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsCateogryApi - functional programming interface
 * @export
 */
export const UtilsCateogryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsCateogryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsCategoryDto} createUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsCategoryControllerCreate(createUtilsCategoryDto: CreateUtilsCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsCategoryControllerCreate(createUtilsCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUtilsCategoryDto} deleteUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsCategoryControllerDelete(deleteUtilsCategoryDto: DeleteUtilsCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsCategoryControllerDelete(deleteUtilsCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUtilsCategoryDto} editUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsCategoryControllerEdit(editUtilsCategoryDto: EditUtilsCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsCategoryControllerEdit(editUtilsCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsCategoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsCategoryControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsCateogryApi - factory interface
 * @export
 */
export const UtilsCateogryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsCateogryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsCategoryDto} createUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsCategoryControllerCreate(createUtilsCategoryDto: CreateUtilsCategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsCategoryControllerCreate(createUtilsCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUtilsCategoryDto} deleteUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsCategoryControllerDelete(deleteUtilsCategoryDto: DeleteUtilsCategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsCategoryControllerDelete(deleteUtilsCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUtilsCategoryDto} editUtilsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsCategoryControllerEdit(editUtilsCategoryDto: EditUtilsCategoryDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsCategoryControllerEdit(editUtilsCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsCategoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.utilsCategoryControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsCateogryApi - object-oriented interface
 * @export
 * @class UtilsCateogryApi
 * @extends {BaseAPI}
 */
export class UtilsCateogryApi extends BaseAPI {
    /**
     * 
     * @param {CreateUtilsCategoryDto} createUtilsCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsCateogryApi
     */
    public utilsCategoryControllerCreate(createUtilsCategoryDto: CreateUtilsCategoryDto, options?: AxiosRequestConfig) {
        return UtilsCateogryApiFp(this.configuration).utilsCategoryControllerCreate(createUtilsCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUtilsCategoryDto} deleteUtilsCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsCateogryApi
     */
    public utilsCategoryControllerDelete(deleteUtilsCategoryDto: DeleteUtilsCategoryDto, options?: AxiosRequestConfig) {
        return UtilsCateogryApiFp(this.configuration).utilsCategoryControllerDelete(deleteUtilsCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUtilsCategoryDto} editUtilsCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsCateogryApi
     */
    public utilsCategoryControllerEdit(editUtilsCategoryDto: EditUtilsCategoryDto, options?: AxiosRequestConfig) {
        return UtilsCateogryApiFp(this.configuration).utilsCategoryControllerEdit(editUtilsCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsCateogryApi
     */
    public utilsCategoryControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return UtilsCateogryApiFp(this.configuration).utilsCategoryControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsDepartmentApi - axios parameter creator
 * @export
 */
export const UtilsDepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUtilsDepartmentDto} createUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsDepartmentControllerCreate: async (createUtilsDepartmentDto: CreateUtilsDepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUtilsDepartmentDto' is not null or undefined
            assertParamExists('utilsDepartmentControllerCreate', 'createUtilsDepartmentDto', createUtilsDepartmentDto)
            const localVarPath = `/api/v1/utils-department/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUtilsDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUtilsDepartmentDto} deleteUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsDepartmentControllerDelete: async (deleteUtilsDepartmentDto: DeleteUtilsDepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUtilsDepartmentDto' is not null or undefined
            assertParamExists('utilsDepartmentControllerDelete', 'deleteUtilsDepartmentDto', deleteUtilsDepartmentDto)
            const localVarPath = `/api/v1/utils-department/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUtilsDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUtilsDepartmentDto} editUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsDepartmentControllerEdit: async (editUtilsDepartmentDto: EditUtilsDepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUtilsDepartmentDto' is not null or undefined
            assertParamExists('utilsDepartmentControllerEdit', 'editUtilsDepartmentDto', editUtilsDepartmentDto)
            const localVarPath = `/api/v1/utils-department/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUtilsDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsDepartmentControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils-department/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsDepartmentApi - functional programming interface
 * @export
 */
export const UtilsDepartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsDepartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsDepartmentDto} createUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsDepartmentControllerCreate(createUtilsDepartmentDto: CreateUtilsDepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsDepartmentControllerCreate(createUtilsDepartmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUtilsDepartmentDto} deleteUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsDepartmentControllerDelete(deleteUtilsDepartmentDto: DeleteUtilsDepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsDepartmentControllerDelete(deleteUtilsDepartmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUtilsDepartmentDto} editUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsDepartmentControllerEdit(editUtilsDepartmentDto: EditUtilsDepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsDepartmentControllerEdit(editUtilsDepartmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsDepartmentControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsDepartmentControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsDepartmentApi - factory interface
 * @export
 */
export const UtilsDepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsDepartmentApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsDepartmentDto} createUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsDepartmentControllerCreate(createUtilsDepartmentDto: CreateUtilsDepartmentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsDepartmentControllerCreate(createUtilsDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUtilsDepartmentDto} deleteUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsDepartmentControllerDelete(deleteUtilsDepartmentDto: DeleteUtilsDepartmentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsDepartmentControllerDelete(deleteUtilsDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUtilsDepartmentDto} editUtilsDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsDepartmentControllerEdit(editUtilsDepartmentDto: EditUtilsDepartmentDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsDepartmentControllerEdit(editUtilsDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsDepartmentControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.utilsDepartmentControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsDepartmentApi - object-oriented interface
 * @export
 * @class UtilsDepartmentApi
 * @extends {BaseAPI}
 */
export class UtilsDepartmentApi extends BaseAPI {
    /**
     * 
     * @param {CreateUtilsDepartmentDto} createUtilsDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsDepartmentApi
     */
    public utilsDepartmentControllerCreate(createUtilsDepartmentDto: CreateUtilsDepartmentDto, options?: AxiosRequestConfig) {
        return UtilsDepartmentApiFp(this.configuration).utilsDepartmentControllerCreate(createUtilsDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUtilsDepartmentDto} deleteUtilsDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsDepartmentApi
     */
    public utilsDepartmentControllerDelete(deleteUtilsDepartmentDto: DeleteUtilsDepartmentDto, options?: AxiosRequestConfig) {
        return UtilsDepartmentApiFp(this.configuration).utilsDepartmentControllerDelete(deleteUtilsDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUtilsDepartmentDto} editUtilsDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsDepartmentApi
     */
    public utilsDepartmentControllerEdit(editUtilsDepartmentDto: EditUtilsDepartmentDto, options?: AxiosRequestConfig) {
        return UtilsDepartmentApiFp(this.configuration).utilsDepartmentControllerEdit(editUtilsDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsDepartmentApi
     */
    public utilsDepartmentControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return UtilsDepartmentApiFp(this.configuration).utilsDepartmentControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsPrePOStatusApi - axios parameter creator
 * @export
 */
export const UtilsPrePOStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUtilsPrePoStatusDto} createUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsPrePoStatusControllerCreate: async (createUtilsPrePoStatusDto: CreateUtilsPrePoStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUtilsPrePoStatusDto' is not null or undefined
            assertParamExists('utilsPrePoStatusControllerCreate', 'createUtilsPrePoStatusDto', createUtilsPrePoStatusDto)
            const localVarPath = `/api/v1/utils-pre-po-status/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUtilsPrePoStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUtilsPrePoStatusDto} deleteUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsPrePoStatusControllerDelete: async (deleteUtilsPrePoStatusDto: DeleteUtilsPrePoStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUtilsPrePoStatusDto' is not null or undefined
            assertParamExists('utilsPrePoStatusControllerDelete', 'deleteUtilsPrePoStatusDto', deleteUtilsPrePoStatusDto)
            const localVarPath = `/api/v1/utils-pre-po-status/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUtilsPrePoStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUtilsPrePoStatusDto} editUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsPrePoStatusControllerEdit: async (editUtilsPrePoStatusDto: EditUtilsPrePoStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUtilsPrePoStatusDto' is not null or undefined
            assertParamExists('utilsPrePoStatusControllerEdit', 'editUtilsPrePoStatusDto', editUtilsPrePoStatusDto)
            const localVarPath = `/api/v1/utils-pre-po-status/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUtilsPrePoStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsPrePoStatusControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils-pre-po-status/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsPrePOStatusApi - functional programming interface
 * @export
 */
export const UtilsPrePOStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsPrePOStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsPrePoStatusDto} createUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsPrePoStatusControllerCreate(createUtilsPrePoStatusDto: CreateUtilsPrePoStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsPrePoStatusControllerCreate(createUtilsPrePoStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUtilsPrePoStatusDto} deleteUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsPrePoStatusControllerDelete(deleteUtilsPrePoStatusDto: DeleteUtilsPrePoStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsPrePoStatusControllerDelete(deleteUtilsPrePoStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUtilsPrePoStatusDto} editUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsPrePoStatusControllerEdit(editUtilsPrePoStatusDto: EditUtilsPrePoStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsPrePoStatusControllerEdit(editUtilsPrePoStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsPrePoStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsPrePoStatusControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsPrePOStatusApi - factory interface
 * @export
 */
export const UtilsPrePOStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsPrePOStatusApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsPrePoStatusDto} createUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsPrePoStatusControllerCreate(createUtilsPrePoStatusDto: CreateUtilsPrePoStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsPrePoStatusControllerCreate(createUtilsPrePoStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUtilsPrePoStatusDto} deleteUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsPrePoStatusControllerDelete(deleteUtilsPrePoStatusDto: DeleteUtilsPrePoStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsPrePoStatusControllerDelete(deleteUtilsPrePoStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUtilsPrePoStatusDto} editUtilsPrePoStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsPrePoStatusControllerEdit(editUtilsPrePoStatusDto: EditUtilsPrePoStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsPrePoStatusControllerEdit(editUtilsPrePoStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsPrePoStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.utilsPrePoStatusControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsPrePOStatusApi - object-oriented interface
 * @export
 * @class UtilsPrePOStatusApi
 * @extends {BaseAPI}
 */
export class UtilsPrePOStatusApi extends BaseAPI {
    /**
     * 
     * @param {CreateUtilsPrePoStatusDto} createUtilsPrePoStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsPrePOStatusApi
     */
    public utilsPrePoStatusControllerCreate(createUtilsPrePoStatusDto: CreateUtilsPrePoStatusDto, options?: AxiosRequestConfig) {
        return UtilsPrePOStatusApiFp(this.configuration).utilsPrePoStatusControllerCreate(createUtilsPrePoStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUtilsPrePoStatusDto} deleteUtilsPrePoStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsPrePOStatusApi
     */
    public utilsPrePoStatusControllerDelete(deleteUtilsPrePoStatusDto: DeleteUtilsPrePoStatusDto, options?: AxiosRequestConfig) {
        return UtilsPrePOStatusApiFp(this.configuration).utilsPrePoStatusControllerDelete(deleteUtilsPrePoStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUtilsPrePoStatusDto} editUtilsPrePoStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsPrePOStatusApi
     */
    public utilsPrePoStatusControllerEdit(editUtilsPrePoStatusDto: EditUtilsPrePoStatusDto, options?: AxiosRequestConfig) {
        return UtilsPrePOStatusApiFp(this.configuration).utilsPrePoStatusControllerEdit(editUtilsPrePoStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsPrePOStatusApi
     */
    public utilsPrePoStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return UtilsPrePOStatusApiFp(this.configuration).utilsPrePoStatusControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsStageApi - axios parameter creator
 * @export
 */
export const UtilsStageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUtilsStageDto} createUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStageControllerCreate: async (createUtilsStageDto: CreateUtilsStageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUtilsStageDto' is not null or undefined
            assertParamExists('utilsStageControllerCreate', 'createUtilsStageDto', createUtilsStageDto)
            const localVarPath = `/api/v1/utils-stage/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUtilsStageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUtilsStageDto} deleteUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStageControllerDelete: async (deleteUtilsStageDto: DeleteUtilsStageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUtilsStageDto' is not null or undefined
            assertParamExists('utilsStageControllerDelete', 'deleteUtilsStageDto', deleteUtilsStageDto)
            const localVarPath = `/api/v1/utils-stage/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUtilsStageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUtilsStageDto} editUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStageControllerEdit: async (editUtilsStageDto: EditUtilsStageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUtilsStageDto' is not null or undefined
            assertParamExists('utilsStageControllerEdit', 'editUtilsStageDto', editUtilsStageDto)
            const localVarPath = `/api/v1/utils-stage/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUtilsStageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStageControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils-stage/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsStageApi - functional programming interface
 * @export
 */
export const UtilsStageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsStageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsStageDto} createUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsStageControllerCreate(createUtilsStageDto: CreateUtilsStageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsStageControllerCreate(createUtilsStageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUtilsStageDto} deleteUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsStageControllerDelete(deleteUtilsStageDto: DeleteUtilsStageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsStageControllerDelete(deleteUtilsStageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUtilsStageDto} editUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsStageControllerEdit(editUtilsStageDto: EditUtilsStageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsStageControllerEdit(editUtilsStageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsStageControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsStageControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsStageApi - factory interface
 * @export
 */
export const UtilsStageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsStageApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsStageDto} createUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStageControllerCreate(createUtilsStageDto: CreateUtilsStageDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsStageControllerCreate(createUtilsStageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUtilsStageDto} deleteUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStageControllerDelete(deleteUtilsStageDto: DeleteUtilsStageDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsStageControllerDelete(deleteUtilsStageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUtilsStageDto} editUtilsStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStageControllerEdit(editUtilsStageDto: EditUtilsStageDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsStageControllerEdit(editUtilsStageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStageControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.utilsStageControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsStageApi - object-oriented interface
 * @export
 * @class UtilsStageApi
 * @extends {BaseAPI}
 */
export class UtilsStageApi extends BaseAPI {
    /**
     * 
     * @param {CreateUtilsStageDto} createUtilsStageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsStageApi
     */
    public utilsStageControllerCreate(createUtilsStageDto: CreateUtilsStageDto, options?: AxiosRequestConfig) {
        return UtilsStageApiFp(this.configuration).utilsStageControllerCreate(createUtilsStageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUtilsStageDto} deleteUtilsStageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsStageApi
     */
    public utilsStageControllerDelete(deleteUtilsStageDto: DeleteUtilsStageDto, options?: AxiosRequestConfig) {
        return UtilsStageApiFp(this.configuration).utilsStageControllerDelete(deleteUtilsStageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUtilsStageDto} editUtilsStageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsStageApi
     */
    public utilsStageControllerEdit(editUtilsStageDto: EditUtilsStageDto, options?: AxiosRequestConfig) {
        return UtilsStageApiFp(this.configuration).utilsStageControllerEdit(editUtilsStageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsStageApi
     */
    public utilsStageControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return UtilsStageApiFp(this.configuration).utilsStageControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsStatusApi - axios parameter creator
 * @export
 */
export const UtilsStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUtilsStatusDto} createUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStatusControllerCreate: async (createUtilsStatusDto: CreateUtilsStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUtilsStatusDto' is not null or undefined
            assertParamExists('utilsStatusControllerCreate', 'createUtilsStatusDto', createUtilsStatusDto)
            const localVarPath = `/api/v1/utils-status/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUtilsStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUtilsStatusDto} deleteUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStatusControllerDelete: async (deleteUtilsStatusDto: DeleteUtilsStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUtilsStatusDto' is not null or undefined
            assertParamExists('utilsStatusControllerDelete', 'deleteUtilsStatusDto', deleteUtilsStatusDto)
            const localVarPath = `/api/v1/utils-status/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUtilsStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUtilsStatusDto} editUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStatusControllerEdit: async (editUtilsStatusDto: EditUtilsStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUtilsStatusDto' is not null or undefined
            assertParamExists('utilsStatusControllerEdit', 'editUtilsStatusDto', editUtilsStatusDto)
            const localVarPath = `/api/v1/utils-status/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUtilsStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStatusControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils-status/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsStatusApi - functional programming interface
 * @export
 */
export const UtilsStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsStatusDto} createUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsStatusControllerCreate(createUtilsStatusDto: CreateUtilsStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsStatusControllerCreate(createUtilsStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUtilsStatusDto} deleteUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsStatusControllerDelete(deleteUtilsStatusDto: DeleteUtilsStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsStatusControllerDelete(deleteUtilsStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUtilsStatusDto} editUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsStatusControllerEdit(editUtilsStatusDto: EditUtilsStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsStatusControllerEdit(editUtilsStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsStatusControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsStatusApi - factory interface
 * @export
 */
export const UtilsStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsStatusApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsStatusDto} createUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStatusControllerCreate(createUtilsStatusDto: CreateUtilsStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsStatusControllerCreate(createUtilsStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUtilsStatusDto} deleteUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStatusControllerDelete(deleteUtilsStatusDto: DeleteUtilsStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsStatusControllerDelete(deleteUtilsStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUtilsStatusDto} editUtilsStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStatusControllerEdit(editUtilsStatusDto: EditUtilsStatusDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsStatusControllerEdit(editUtilsStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.utilsStatusControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsStatusApi - object-oriented interface
 * @export
 * @class UtilsStatusApi
 * @extends {BaseAPI}
 */
export class UtilsStatusApi extends BaseAPI {
    /**
     * 
     * @param {CreateUtilsStatusDto} createUtilsStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsStatusApi
     */
    public utilsStatusControllerCreate(createUtilsStatusDto: CreateUtilsStatusDto, options?: AxiosRequestConfig) {
        return UtilsStatusApiFp(this.configuration).utilsStatusControllerCreate(createUtilsStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUtilsStatusDto} deleteUtilsStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsStatusApi
     */
    public utilsStatusControllerDelete(deleteUtilsStatusDto: DeleteUtilsStatusDto, options?: AxiosRequestConfig) {
        return UtilsStatusApiFp(this.configuration).utilsStatusControllerDelete(deleteUtilsStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUtilsStatusDto} editUtilsStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsStatusApi
     */
    public utilsStatusControllerEdit(editUtilsStatusDto: EditUtilsStatusDto, options?: AxiosRequestConfig) {
        return UtilsStatusApiFp(this.configuration).utilsStatusControllerEdit(editUtilsStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsStatusApi
     */
    public utilsStatusControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return UtilsStatusApiFp(this.configuration).utilsStatusControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsUnitApi - axios parameter creator
 * @export
 */
export const UtilsUnitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUtilsUnitDto} createUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsUnitControllerCreate: async (createUtilsUnitDto: CreateUtilsUnitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUtilsUnitDto' is not null or undefined
            assertParamExists('utilsUnitControllerCreate', 'createUtilsUnitDto', createUtilsUnitDto)
            const localVarPath = `/api/v1/utils-unit/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUtilsUnitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUtilsUnitDto} deleteUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsUnitControllerDelete: async (deleteUtilsUnitDto: DeleteUtilsUnitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUtilsUnitDto' is not null or undefined
            assertParamExists('utilsUnitControllerDelete', 'deleteUtilsUnitDto', deleteUtilsUnitDto)
            const localVarPath = `/api/v1/utils-unit/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUtilsUnitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUtilsUnitDto} editUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsUnitControllerEdit: async (editUtilsUnitDto: EditUtilsUnitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUtilsUnitDto' is not null or undefined
            assertParamExists('utilsUnitControllerEdit', 'editUtilsUnitDto', editUtilsUnitDto)
            const localVarPath = `/api/v1/utils-unit/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUtilsUnitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsUnitControllerGetDataAsList: async (search?: string, limit?: number, offset?: number, order?: object, filter?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utils-unit/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth-client-secret required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-client-secret", configuration)

            // authentication oauth-token-code required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-code", configuration)

            // authentication oauth-token-refresh-token required
            await setApiKeyToObject(localVarHeaderParameter, "oauth-token-refresh-token", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsUnitApi - functional programming interface
 * @export
 */
export const UtilsUnitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsUnitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsUnitDto} createUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsUnitControllerCreate(createUtilsUnitDto: CreateUtilsUnitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsUnitControllerCreate(createUtilsUnitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUtilsUnitDto} deleteUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsUnitControllerDelete(deleteUtilsUnitDto: DeleteUtilsUnitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsUnitControllerDelete(deleteUtilsUnitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUtilsUnitDto} editUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsUnitControllerEdit(editUtilsUnitDto: EditUtilsUnitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsUnitControllerEdit(editUtilsUnitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsUnitControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsBrandControllerGetDataAsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsUnitControllerGetDataAsList(search, limit, offset, order, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsUnitApi - factory interface
 * @export
 */
export const UtilsUnitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsUnitApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUtilsUnitDto} createUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsUnitControllerCreate(createUtilsUnitDto: CreateUtilsUnitDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsUnitControllerCreate(createUtilsUnitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUtilsUnitDto} deleteUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsUnitControllerDelete(deleteUtilsUnitDto: DeleteUtilsUnitDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsUnitControllerDelete(deleteUtilsUnitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUtilsUnitDto} editUtilsUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsUnitControllerEdit(editUtilsUnitDto: EditUtilsUnitDto, options?: any): AxiosPromise<MessageResponseDto> {
            return localVarFp.utilsUnitControllerEdit(editUtilsUnitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {object} [order] 
         * @param {object} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsUnitControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: any): AxiosPromise<UtilsBrandControllerGetDataAsList200Response> {
            return localVarFp.utilsUnitControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsUnitApi - object-oriented interface
 * @export
 * @class UtilsUnitApi
 * @extends {BaseAPI}
 */
export class UtilsUnitApi extends BaseAPI {
    /**
     * 
     * @param {CreateUtilsUnitDto} createUtilsUnitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsUnitApi
     */
    public utilsUnitControllerCreate(createUtilsUnitDto: CreateUtilsUnitDto, options?: AxiosRequestConfig) {
        return UtilsUnitApiFp(this.configuration).utilsUnitControllerCreate(createUtilsUnitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUtilsUnitDto} deleteUtilsUnitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsUnitApi
     */
    public utilsUnitControllerDelete(deleteUtilsUnitDto: DeleteUtilsUnitDto, options?: AxiosRequestConfig) {
        return UtilsUnitApiFp(this.configuration).utilsUnitControllerDelete(deleteUtilsUnitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUtilsUnitDto} editUtilsUnitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsUnitApi
     */
    public utilsUnitControllerEdit(editUtilsUnitDto: EditUtilsUnitDto, options?: AxiosRequestConfig) {
        return UtilsUnitApiFp(this.configuration).utilsUnitControllerEdit(editUtilsUnitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {object} [order] 
     * @param {object} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsUnitApi
     */
    public utilsUnitControllerGetDataAsList(search?: string, limit?: number, offset?: number, order?: object, filter?: object, options?: AxiosRequestConfig) {
        return UtilsUnitApiFp(this.configuration).utilsUnitControllerGetDataAsList(search, limit, offset, order, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


