import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import "./inline-text.scss";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import classNames from "classnames";

/* eslint-disable-next-line */
export interface InlineTextProps {
  className?: string;
  inputClass?: string;
  textStart?: "start" | "center";
}

export function InlineText({
  inputClass,
  className,
  textStart = "start",
}: InlineTextProps) {
  const [text, setText] = useState("");
  return (
    <div className={classNames("inline-text", textStart)}>
      <Inplace className={classNames("inline-text", className)} closable>
        <InplaceDisplay>{text}</InplaceDisplay>
        <InplaceContent>
          <InputText
            className={classNames(inputClass)}
            value={text}
            onChange={(e) => setText(e.target.value)}
            autoFocus
          />
        </InplaceContent>
      </Inplace>
    </div>
  );
}

export default InlineText;
